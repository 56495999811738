<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 class="uppercaseText" fxFlexAlign="center" mat-dialog-title>{{'DIALOGS.TRANSFERRECIPE.TITLE' | i18next }}</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()">
        <img class="invertedWhite" src="/assets/general/close.svg">
      </button>
    </div>
  </div>


  <div class="inputs" fxLayout="column">
    <mat-dialog-content fxLayout="column">
      <div fxLayout="row" class="selectorDiv">
        <p class="label">{{'DIALOGS.NEWMODULE.ORGANIZATION' | i18next }}</p>
        <mat-select class="selectElement"
                    [(ngModel)]="selectedOrganisation">
          <mat-select-trigger *ngIf="selectedOrganisation" class="selecttrigger">
            {{selectedOrganisation.name}}
          </mat-select-trigger>
          <mat-option *ngFor="let org of organisations"
                      [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </div>
    </mat-dialog-content>

  </div>

  <div class="savediv" fxLayout="row" fxFlexAlign="center">
    <button mat-button (click)="transferRecipe()" *ngIf="savePossible()">
      <img class="invertedWhite" src="/assets/general/save.svg">
    </button>
  </div>

</section>
