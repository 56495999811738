import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerModule} from '../../../classes/customer-module';
import {ModuleDefinition} from '../../../classes/module-definition';
import {Conveyor} from '../../../classes/conveyor';
import {ModulePlan} from '../../../classes/module-plan';
import {CatalogModule} from '../../../classes/catalog-module';
import {UserManagementService} from '../../../services/user-management.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ConfirmMessageBoxComponent} from '../../dialogs/confirm-message-box/confirm-message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-support-catalog',
  templateUrl: './support-catalog.component.html',
  styleUrls: ['./support-catalog.component.css']
})
export class SupportCatalogComponent implements OnInit, AfterViewChecked {
  @Output() OnNoSupportSelected = new EventEmitter();
  @Output() closeSupportModuleSelection = new EventEmitter();
  @Output() OnCustomerSupportModuleSelected = new EventEmitter<CustomerModule>();
  @Output() OnSupportModuleSelected = new EventEmitter<ModuleDefinition>();
  @Input() set currentSupportModules(value: CustomerModule[]) {
    if (!this._currentSupportModules) {
      this._currentSupportModules = value;
      this.OwnSupportModules = this.GetOwnCustomerModules();
      this.OtherSupportModules = this.GetOtherCustomerModules();
      this.cdRef.markForCheck();
    } else if (value.length !== this._currentSupportModules.length) {
      this._currentSupportModules = value;
      this.OwnSupportModules = this.GetOwnCustomerModules();
      this.OtherSupportModules = this.GetOtherCustomerModules();
      this.cdRef.markForCheck();
    } else {
      this._currentSupportModules = value;
    }


  }
  @Input() activeModule: ModulePlan;
  @Input() set Visible(value: boolean) {
    this.OwnSupportModules = this.GetOwnCustomerModules();
    this.OtherSupportModules = this.GetOtherCustomerModules();
    this.cdRef.markForCheck();


  }

  @ViewChild('deviceContainer', { read: ElementRef, static: true}) public widgetsContent: ElementRef<any>;

  get CurrentSupportModules(): CustomerModule[] {
    return this._currentSupportModules;
  }

  private _currentSupportModules:  CustomerModule[] = [];
  customerSupportModules: CustomerModule[];
  public OpenSupportModules: CatalogModule[];
  public OwnSupportModules: CatalogModule[];
  public OtherSupportModules: CatalogModule[];
  supportModules: ModuleDefinition[];
  customerId: string;
  OwnVisible = true;
  IfeVisible = false;
  ExtVisible = true;

  constructor(public usermanagement: UserManagementService,
              public server: ServerCommunicationService,
              public moduleHandling: ModuleHandlingService,
              public translate: BluectrlTranslateService,
              public dialog: MatDialog,
              private cdRef: ChangeDetectorRef) {
    usermanagement.DataLoadingFinished.subscribe(this.UserLogedIn.bind(this));
    // server.OnModulesLoaded.subscribe(this.ModulesLoaded.bind(this));



  }

  ngOnInit(): void {
    if (this.server.Loaded === true && this.usermanagement.DataLoaded) {
      if (this.usermanagement.currentUser !== null && this.usermanagement.currentUser !== undefined) {
        this.customerId = this.usermanagement.currentUser.CustomerId;
        this.customerSupportModules = this.server.GetCurrentCustomerModules().filter(ex => ex.Module.SupportModule === true);

      }
      this.supportModules = this.server.GetModules().filter(ex => ex.SupportModule === true);
    }

    this.OwnSupportModules = this.GetOwnCustomerModules();
    this.OtherSupportModules = this.GetOtherCustomerModules();
    this.OpenSupportModules =  this.getOpenSupportModules();
    this.cdRef.markForCheck();

  }

  CloseClick() {
    this.closeSupportModuleSelection.emit();
  }

  UserLogedIn() {
    if (this.server.Loaded === true) {
      if (this.usermanagement.DataLoaded) {
        this.customerId = this.usermanagement.currentUser.CustomerId;
        this.customerSupportModules = this.server.GetCurrentCustomerModules().filter(ex => ex.Module.SupportModule === true);
        this.supportModules = this.server.GetModules().filter(ex => ex.SupportModule === true);
        this.cdRef.markForCheck();
      }
    }
  }

  ModuleSelected(module: ModuleDefinition) {
    if (this.activeModule.customerModule) {
      const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOTPOSSIBLE');
      const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.CUSTOMERMODULECATALOGSUPPORT');
      this.dialog.open(ConfirmMessageBoxComponent,
        {panelClass: 'panelclass', data: {header: msg, content: content}});

    } else {
      this.OnSupportModuleSelected.emit(module);
      this.closeSupportModuleSelection.emit();
    }


  }

  NotConnectedSelected() {
    this.OnNoSupportSelected.emit();
    this.closeSupportModuleSelection.emit();
  }

  CustomerModuleSelected(customerModule: CustomerModule) {
    this.OnCustomerSupportModuleSelected.emit(customerModule);
    this.closeSupportModuleSelection.emit();
  }

  getOpenSupportModules(): CatalogModule[] {
    const modulesToUse: CatalogModule[] = [];

    if (this.moduleHandling.ActiveProject && this.moduleHandling.ActiveProject.SupportModules) {
      for (const m of this.moduleHandling.ActiveProject.SupportModules) {
        if (m.customerModule !== null && m.customerModule !== undefined) {
          if (m.haveAvailableConnection()) {
            modulesToUse.push(new CatalogModule(m.customerModule));
          }
        }
      }
    }

    return modulesToUse;
  }

  GetOwnCustomerModules(): CatalogModule[] {

    const modules = this.GetCustomerModules();

    if (modules.length > 0 && this.usermanagement.currentUser) {
      // OWN
      return modules.filter(ex => ex.CustomerModule.Ward.Id === this.usermanagement.currentUser.CustomerId ||
        ex.CustomerModule.Holder === this.usermanagement.currentUser.CustomerId ||
        ex.CustomerModule.Owner === this.usermanagement.currentUser.CustomerId);
    } else {
      return [];
    }

  }

  GetOtherCustomerModules(): CatalogModule[] {

    const modules = this.GetCustomerModules();

    if (modules.length > 0 && this.usermanagement.currentUser) {
      // OWN
      return modules.filter(ex => ex.CustomerModule.Ward.Id !== this.usermanagement.currentUser.CustomerId);
    } else {
      return [];
    }

  }

  GetOwnCustomerModulesByWard(wardid: string): CatalogModule[] {

    const modules = this.OtherSupportModules;

    if (modules &&  modules.length > 0 && this.usermanagement.currentUser) {
      // OWN
      return modules.filter(ex => ex.CustomerModule.Ward.Id === wardid);
    } else {
      return [];
    }

  }

  GetCustomerModules(): CatalogModule[] {

    let modules: CustomerModule[] = this.customerSupportModules;

    if (this.customerSupportModules && this.customerSupportModules.length > 0) {

      if (this._currentSupportModules !== null && this._currentSupportModules !== undefined && this._currentSupportModules.length > 0) {

        for (const cm of this._currentSupportModules) {

          const fts = modules.find(ex => ex.CustomerModuleId === cm.CustomerModuleId);

          if (fts) {
            modules = modules.filter(ex => ex.CustomerModuleId !== cm.CustomerModuleId);
          } else {
            const ftsKey = modules.find(ex => ex.Module.Key === cm.Module.Key && ex.Customer === cm.Customer);
            if (ftsKey) {
              ftsKey.CustomerModuleId = cm.CustomerModuleId;
              modules = modules.filter(ex => ex.CustomerModuleId !== cm.CustomerModuleId);
            }
          }
        }
        const modulesToUse: CatalogModule[] = [];
        for (const m of modules) {
          if (!modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
            ex.CustomerModule.Module.Version === m.Module.Version &&
            ex.CustomerModule.Ward.Id === m.Ward.Id)) {
            modulesToUse.push(new CatalogModule(m));
          } else {
            const mdl = modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
              ex.CustomerModule.Module.Version === m.Module.Version &&
              ex.CustomerModule.Ward.Id === m.Ward.Id);
            mdl.Counter += 1;
          }
        }
        this.cdRef.markForCheck();
        return modulesToUse;
      } else {

        const modulesToUse: CatalogModule[] = [];

        for (const m of this.customerSupportModules) {
          if (!modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
            ex.CustomerModule.Module.Version === m.Module.Version &&
            ex.CustomerModule.Ward.Id === m.Ward.Id)) {
            modulesToUse.push(new CatalogModule(m));
          } else {
            const mdl = modulesToUse.find(ex => ex.CustomerModule.Module.Type === m.Module.Type &&
              ex.CustomerModule.Module.Version === m.Module.Version &&
              ex.CustomerModule.Ward.Id === m.Ward.Id);
            mdl.Counter += 1;
          }
        }
        this.cdRef.markForCheck();
        return modulesToUse;
      }

    }
    this.cdRef.markForCheck();
    return [];

  }

  public ScrollDown() {
    this.widgetsContent.nativeElement.scrollTop =  this.widgetsContent.nativeElement.scrollTop + 50;
    this.cdRef.markForCheck();
  }

  public ScrollUp() {
    this.widgetsContent.nativeElement.scrollTop =
      this.widgetsContent.nativeElement.scrollTop >= 0 ?
        this.widgetsContent.nativeElement.scrollTop - 50 :
        0;
    this.cdRef.markForCheck();
  }

  public DownScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      const off = this.widgetsContent.nativeElement.clientHeight + this.widgetsContent.nativeElement.scrollTop;
      if (off < this.widgetsContent.nativeElement.scrollHeight) {
        return true;
      }
    }
    return false;
  }

  public UpScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      if (this.widgetsContent.nativeElement.scrollTop > 0) {

        return true;
      }
    }
    return false;

  }

  ngAfterViewChecked(): void {
    // this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

}
