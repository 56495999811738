import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-decision-message-box',
  templateUrl: './decision-message-box.component.html',
  styleUrls: ['./decision-message-box.component.css']
})
export class DecisionMessageBoxComponent implements OnInit {

  constructor(public DialogRef: MatDialogRef<DecisionMessageBoxComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {content: string, trueOption: string, falseOption: string}) { }

  ngOnInit(): void {
  }

  public Confirm() {
    this.DialogRef.close(true);
  }

  public Discline() {
    this.DialogRef.close(false);
  }

}
