import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ModuleComponent} from '../../../classes/module-component';
import {ComponentVariable} from '../../../classes/component-variable';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';

@Component({
  selector: 'app-component-monitoring',
  templateUrl: './component-monitoring.component.html',
  styleUrls: ['./component-monitoring.component.css']
})
export class ComponentMonitoringComponent implements OnInit {

  private _ModeSw: ModeSwitch;
  private _inventoryView = false;
  OwnVisible = false;

  @Input() set inventoryView(value: boolean) {
    this._inventoryView = value;
  }
  @Input() device: ModuleComponent;
  @Input() set switchModeString(value: string) {

    if (value === null) {
      this._ModeSw = ModeSwitch.NONE;
    } else if (value === 'service') {
      this._ModeSw = ModeSwitch.SERVICE;
    } else {
      this._ModeSw = ModeSwitch.AUTOMATIC;
    }
  }
  @Input() set switchMode(value: ModeSwitch) {
    this._ModeSw = value;
  }

  @Output() OnSendToModule = new EventEmitter<ModuleComponent>();

  get switchMode(): ModeSwitch {
    return this._ModeSw;
  }

  constructor(public moduleHandling: ModuleHandlingService,
              public translate: BluectrlTranslateService) {
    this.moduleHandling.OnModuleDeviceSelected.subscribe(this.ModuleDeviceSelected.bind(this));
  }

  ngOnInit(): void {

  }

  GetMonitorValues(): ComponentVariable[] {
    return this.device.Monitorings.filter(ex => ex.Visible === true);
  }

  public ShowConfig(deviceValue: ComponentVariable): boolean {

    return false;
  }

  public ModuleDeviceSelected(moduleDevice: ModuleComponent) {
    this.OwnVisible = moduleDevice.PlcKey === this.device.PlcKey;
  }

  public GetCurrentValue(moduledevicevalue: ComponentVariable): any {
    if (moduledevicevalue.States.length > 0) {
      if (moduledevicevalue.CurrentValue != null && moduledevicevalue.CurrentValue !== undefined) {
        const fnd = moduledevicevalue.States.find(ex => ex.State === moduledevicevalue.CurrentValue);
        if (fnd) {
          const translated =  this.translate.GetTranslation(fnd.GetTranslationID());
          if (translated !== fnd.GetTranslationID()) {
            return translated;
          } else {
            return fnd.GetName();
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return moduledevicevalue.CurrentValue;
    }
  }

  public GetUnitTranslation(moduledevicevalue: ComponentVariable): string {
    if (moduledevicevalue.getUnitId()) {
      return this.translate.GetTranslation(moduledevicevalue.getUnitId());
    } else {
      return '';
    }
  }

}
