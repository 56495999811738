import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {CustomerModule} from '../../../classes/customer-module';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {ModuleSyncActions} from '../../../classes/enums/module-sync-actions.enum';
import {ModuleSyncActionList} from '../../../classes/module-sync-action-list';
import {ActionInitState} from '../../../classes/enums/action-init-state.enum';
import {InitClusterActionList} from '../../../classes/init-action-list';
import {ModuleServiceService} from '../../../services/module-service.service';
import {WifiServiceNotFoundComponent} from '../wifi-service-not-found/wifi-service-not-found.component';
import {environment} from '../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {InitStateMessage} from '../../../classes/init-state-message';

@Component({
  selector: 'app-service-mode-synchronization-dialog',
  templateUrl: './service-mode-synchronization-dialog.component.html',
  styleUrls: ['./service-mode-synchronization-dialog.component.css']
})
export class ServiceModeSynchronizationDialogComponent implements OnInit, AfterViewInit {

  public actionList = ModuleSyncActionList;
  public customerModule: CustomerModule;
  public InitSucessfull = false;
  private unsubscribe: Subject<void> = new Subject<void>();
  private active = false;

  constructor(private dialogRef: MatDialogRef<ServiceModeSynchronizationDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) data,
              public moduleService: ModuleServiceService) {
    if (data) {
      this.moduleService.ModuleSyncProgress.pipe(takeUntil(this.unsubscribe)).subscribe(this.OnModuleStateInfo.bind(this));
      this.customerModule = data;
      if (!this.moduleService.SetCustomerModule(this.customerModule)) {
        // TODO: info can not start sync
      } else {

      }
    } else {
      this.dialogRef.close(false);
    }

    for (const l of this.actionList) {
      l.state = ActionInitState.open;
    }

  }

  ngOnInit(): void {
    this.active = true;
  }

  ngAfterViewInit() {
    this.StartModuleSync();
  }

  public OnModuleStateInfo(data: InitStateMessage) {
    if (data.ActionId && data.State) {
      const action = this.actionList.find(ex => ex.id === data.ActionId);
      if (action) {
        action.state = data.State;
        if (data.State === ActionInitState.failed && data.ActionId === ModuleSyncActions.connectToWifi) {
          for (const act of this.actionList) {
            act.state = ActionInitState.failed;
          }

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'loginDialogGray';
          dialogConfig.data = environment.ModuleWifiSurFix + this.customerModule.SerialNumber;
          this.dialog.open(WifiServiceNotFoundComponent, dialogConfig);

        } else if (data.State === ActionInitState.success && data.ActionId === ModuleSyncActions.getModuleInfo) {
          setTimeout(() => {
            this.CloseDialog();

          }, 3000);
        }

      } else {
        for (const act of this.actionList) {
          act.state = ActionInitState.failed;
        }
      }
    }
  }

  CloseDialog() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.active = false;
    this.moduleService.AbortModuleStateSync();
    this.dialogRef.close();
  }

  public RetryInit() {
    if (this.actionList.filter(ex => ex.state === ActionInitState.failed).length > 0) {
      this.actionList = InitClusterActionList;
      for (const l of this.actionList) {
        l.state = ActionInitState.open;
      }
      this.StartModuleSync();
    }
  }

  public RetryPossible(): boolean {
    return this.actionList.filter(ex => ex.state === ActionInitState.failed).length > 0;
  }

  public StartModuleSync() {
    if (!this.moduleService.StartSync()) {
      // TODO: Info no sync
    }
  }



}
