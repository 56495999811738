import {Component, Inject, OnInit} from '@angular/core';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {UserManagementService} from '../../../services/user-management.service';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {WebsocketService} from '../../../services/v2/websocket.service';
import {ConfirmMessageBoxComponent} from '../confirm-message-box/confirm-message-box.component';
import {ConnectivityService} from '../../../services/connectivity.service';
import {DatabaseService} from '../../../services/database.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  form: FormGroup;
  ErrorText: string;
  user: any = '';
  password: any = '';
  pin: string = '';
  public loginOngoing = false;
  public retryCounter = 0;
  public hide = true;
  get passwordInput() { return this.form.get('pin'); }

  constructor(private server: ServerCommunicationService,
              private userhandling: UserManagementService,
              private api: BluectrlApiService,
              private fb: FormBuilder,
              public translate: BluectrlTranslateService,
              public websocket: WebsocketService,
              public dialog: MatDialog,
              public database: DatabaseService,
              public connectivity: ConnectivityService,
              private dialogRef: MatDialogRef<LoginDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {

    const location = window.location.href;


    if (location.indexOf('localhost') >= 0) {
      this.user = 'admin@blueline.eds';
      this.password = 'EdsAdmin';
    }

    this.form = fb.group({
      user: [this.user, Validators.required],
      password: [this.password, Validators.required],
      pin: [this.pin, Validators.required]
    });

  }

  ngOnInit() {
    this.retryCounter = 0;

  }

  login() {

    if (this.connectivity.inOfflineMode) {
      this.loginOngoing = true;
      this.ErrorText = '';
      if (this.form.value.user && this.form.value.pin) {
        this.database.getOfflineUser(this.form.value.user).then(data => {
          if (data) {
            if (data.pin === this.form.value.pin) {
              this.userhandling.SetUser(this.form.value.user, this.form.value.pin);
              this.loginOngoing = false;
              this.dialogRef.close(true);
            } else {
              this.ErrorText = this.translate.GetTranslation('MESSAGEBOX.CONTENT.AUTHINCORRECT');
              this.loginOngoing = false;
            }
          } else {
            this.ErrorText = this.translate.GetTranslation('MESSAGEBOX.CONTENT.AUTHINCORRECT');
            this.loginOngoing = false;
          }
        });
      }
    } else {

      this.loginOngoing = true;
      this.ErrorText = '';
      if (this.form.value.user && this.form.value.password) {
        this.api.Login(this.form.value.user, this.form.value.password).subscribe((data: any) => {
          if (this.loginOngoing === true) {
            if (data.token_type === 'bearer') {
              this.userhandling.SetToken(data.access_token);
              // SET CURRENT USER
              this.userhandling.SetUser(this.form.value.user, this.form.value.password);
              this.loginOngoing = false;
              this.dialogRef.close(true);
            }
          }

        }, (error => {
          this.loginOngoing = false;
          if (error.status === 400) {
            this.ErrorText = this.translate.GetTranslation('MESSAGEBOX.CONTENT.AUTHINCORRECT');
          } else {
            // RETRY
            if (this.retryCounter < 3) {
              this.retryCounter = this.retryCounter + 1;
              this.login();
            }
          }

        }));

        setTimeout(() => {
          if (this.loginOngoing === true) {
            this.loginOngoing = false;
            this.ErrorText = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOCONNECTION');
          }
        }, 10000);

      }


      // const res = this.server.Login(this.form.value.user, this.form.value.password);
      // if (res !== 'OK') {
      //  this.ErrorText = res;
      // } else {
      //  this.dialogRef.close();
      // }
    }


  }

}
