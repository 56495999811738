<section fxLayout="column">
  <img class="logo" src="/assets/general/logo_bluectrl_colour.svg">
  <h3>{{'DIALOGS.LOADAPIDATA.TITLE' | i18next }}</h3>
  <div class="spinner" fxLayout="row">
    <mat-progress-spinner class="spinner" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div class="currentState">
    <p *ngIf="currentState === -1">{{'DIALOGS.LOADAPIDATA.INIT' | i18next }}</p>
    <p *ngIf="currentState === 0">{{'DIALOGS.LOADAPIDATA.LOADDMS' | i18next }}</p>
    <p *ngIf="currentState === 1">{{'DIALOGS.LOADAPIDATA.LOADUSER' | i18next }}</p>
    <p *ngIf="currentState === 2">{{'DIALOGS.LOADAPIDATA.LOADMODULES' | i18next }}</p>
    <p *ngIf="currentState === 3">{{'DIALOGS.LOADAPIDATA.LOADRECIPES' | i18next }}</p>
    <p *ngIf="currentState === 5">{{'DIALOGS.LOADAPIDATA.FINISHED' | i18next }}</p>
  </div>
</section>
