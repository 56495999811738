import {Conveyor} from '../conveyor';
import {ConveyorLenghtType} from '../enums/conveyor-lenght-type.enum';

export const MockConveyors: Conveyor[] = [
  new Conveyor('S600', '1111', 60, 'assets/symbol_foerderband.svg', 'B0901', ConveyorLenghtType.short,0),
  new Conveyor('L600', '2222', 60, 'assets/symbol_foerderband.svg', 'B0902', ConveyorLenghtType.long,0),
  new Conveyor('S800', '3333', 80, 'assets/symbol_foerderband.svg', 'B0903', ConveyorLenghtType.short,0),
  new Conveyor('L800', '4444', 80, 'assets/symbol_foerderband.svg', 'B0904', ConveyorLenghtType.long,0),
  new Conveyor('L600M', '5555', 60, 'assets/symbol_foerderband.svg', 'B0905', ConveyorLenghtType.magnet,2),
  new Conveyor('L800M', '6666', 80, 'assets/symbol_foerderband.svg', 'B0906', ConveyorLenghtType.magnet,2),
  new Conveyor('S800S', '7777', 80, 'assets/symbol_foerderband.svg', 'B0907', ConveyorLenghtType.short,0),
  new Conveyor('L800S', '8888', 80, 'assets/symbol_foerderband.svg', 'B0908', ConveyorLenghtType.long,0),
  new Conveyor('L800SM', '9999', 80, 'assets/symbol_foerderband.svg', 'B0909', ConveyorLenghtType.magnet,2),
  new Conveyor('L1100', '1010', 110, 'assets/symbol_foerderband.svg', 'B0910', ConveyorLenghtType.long,0),
];

