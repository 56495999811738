import {Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {SetupTask} from '../../../classes/setup-task';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {ModulePlan} from '../../../classes/module-plan';

@Component({
  selector: 'app-setup-task-list',
  templateUrl: './setup-task-list.component.html',
  styleUrls: ['./setup-task-list.component.css']
})
export class SetupTaskListComponent implements OnInit {

  @Input() Module: ModulePlan;
  @Input() ActiveTasks: SetupTask[];
  @Output() AllDone = new EventEmitter();

  @ViewChild('taskContainer', { read: ElementRef, static: false}) public widgetsContent: ElementRef<any>;

  constructor(public translate: BluectrlTranslateService) { }

  ngOnInit(): void {
  }

  public SetAllDone() {
      for (const t of this.ActiveTasks) {
        t.Done = true;
      }
      this.AllDone.emit();
  }

  public EnableTask(task: SetupTask) {
    task.Done = !task.Done;

    if (this.CheckIfDone()) {
      this.AllDone.emit();
    }

  }

  public GetTaskText(Task: SetupTask): string {
    if (Task.Surfix || Task.Prefix) {
      let surfix = '';
      let prefix = '';

      if (Task.Surfix) {
        if (Task.Surfix.component && Task.Surfix.parameter) {
          const component = this.Module.modul.Components.find(ex => ex.PlcKey === Task.Surfix.component);
          if (component) {
            const param = component.Configs.find(ex => ex.Name === Task.Surfix.parameter);
            if (param) {
              const val = param.getValue();
              if (param.States.length > 0) {
                const state = param.States.find(ex => ex.State === val);
                if (state) {
                  const trans = this.translate.GetTranslation(state.GetTranslationID());
                  if (trans && trans !== state.GetTranslationID()) {
                    surfix = trans;
                  } else {
                    surfix = state.GetName();
                  }
                }

              }
            }
          }
        }
      }

      if (Task.Prefix) {
        if (Task.Prefix.component && Task.Prefix.parameter) {
          const component = this.Module.modul.Components.find(ex => ex.PlcKey === Task.Prefix.component);
          if (component) {
            const param = component.Configs.find(ex => ex.Name === Task.Prefix.parameter);
            if (param) {
              const val = param.getValue();
              if (param.States.length > 0) {
                const state = param.States.find(ex => ex.State === val);
                if (state) {
                  const trans = this.translate.GetTranslation(state.GetTranslationID());
                  if (trans && trans !== state.GetTranslationID()) {
                    prefix = trans;
                  } else {
                    prefix = state.GetName();
                  }
                }

              }
            }
          }
        }
      }

      let transText = this.translate.GetTranslation(Task.GetTranslationIdText());
      if (!transText || transText === Task.GetTranslationIdText()) {
        transText = Task.Text;
      }
      return prefix + ' ' + transText + ' ' + surfix;
    } else {
      let transText = this.translate.GetTranslation(Task.GetTranslationIdText());
      if (!transText || transText === Task.GetTranslationIdText()) {
        transText = Task.Text;
      }
      return transText;
    }
  }

  public CheckIfDone(): boolean {
    let done = true;
    for (const t of this.ActiveTasks) {
      if (t.Done === false) {
        done = false;
      }
    }

    return done;

  }

  public ScrollDown() {
    this.widgetsContent.nativeElement.scrollTop = this.widgetsContent.nativeElement.scrollTop + 50;
  }

  public ScrollUp() {
    this.widgetsContent.nativeElement.scrollTop =
      this.widgetsContent.nativeElement.scrollTop >= 0 ? this.widgetsContent.nativeElement.scrollTop - 50 : 0;
  }

  public DownScrolling(): boolean {

    if (this.widgetsContent !== undefined && this.widgetsContent !== null) {
      const off = this.widgetsContent.nativeElement.clientHeight + this.widgetsContent.nativeElement.scrollTop;
      if (off < this.widgetsContent.nativeElement.scrollHeight) {
        return true;
      }
    }
    return false;
  }

  public UpScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null) {
      if (this.widgetsContent.nativeElement.scrollTop > 0) {
        return true;
      }
    }
    return false;
  }

}
