import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {MenuActionService} from '../../../services/menu-action.service';
import {UserManagementService} from '../../../services/user-management.service';
import {ConnectivityService} from '../../../services/connectivity.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Output() sidenavClose = new EventEmitter();

  constructor(public router: Router,
              private menuaction: MenuActionService,
              public usermanagement: UserManagementService,
              public connectivity: ConnectivityService,

              ) { }

  ngOnInit() {

  }

  public onSidenavClose = () => {

    this.sidenavClose.emit();
  }

  public NewProject() {
    this.menuaction.NewProject();
    this.sidenavClose.emit();
  }

  public LoadProject() {
    this.menuaction.LoadProject();
    this.sidenavClose.emit();
  }

  public SaveProject() {
    this.menuaction.SaveProject();
    this.sidenavClose.emit();
  }

  public ShareProject() {
    this.menuaction.ShareProject();
    this.sidenavClose.emit();
  }



}
