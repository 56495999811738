import {Injectable, EventEmitter} from '@angular/core';
import {Custers} from '../classes/DatabaseObjects/custers';
import * as uuidV4 from 'uuid/v4';
import Dexie, {PromiseExtended} from 'dexie';
import {Batch} from '../classes/batch';
import {Material} from '../classes/material';
import {SensorSortState} from '../classes/DatabaseObjects/sensor-sort-state';
import {UserManagementService} from './user-management.service';
import * as moment from 'moment';
import {BluectrlApiService} from './bluectrl-api.service';
import {json} from 'stream/consumers';
import {User} from '../classes/user';
import {ConnectivityService} from './connectivity.service';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends Dexie {

  recipes: Dexie.Table<any, string>;
  offlineUser: Dexie.Table<any, string>;
  customerModules: Dexie.Table<any, string>;
  clusters: Dexie.Table<Custers, string>;
  batches: Dexie.Table<Batch, string>;
  materials: Dexie.Table<Material, string>;
  sensorSortStates: Dexie.Table<SensorSortState, string>;
  dms: Dexie.Table<any, string>;
  public ApiRecipesLoaded = new EventEmitter();
  public ApiRecipesLoadingError = new EventEmitter();
  public globalRecipes: any[];
  public allGlobalRecipes: any[];

  public OfflineUserRegistered = new EventEmitter<boolean>();

  public dmsCount = 0;
  public latestDMS: any;

  constructor(private usermanagement: UserManagementService,
              private connectivity: ConnectivityService,
              public apiservice: BluectrlApiService) {
    super('BLUECTRL');


    this.version(1).stores({
      recipes: 'id, name, description, author, created, version, valid, customer, recipe',
      clusters: 'Id, ClusterName, IPs, Default',
    });
    this.version(2).stores({
      recipes: 'id, name, description, author, created, version, valid, customer, recipe',
      clusters: 'Id, ClusterName, IPs, Default',
      batches: 'id, name, supplier, date, material, author, customer',
      materials: 'id, code, name, customer',
    });
    this.version(3).stores({
      recipes: 'id, name, description, author, created, version, valid, customer, recipe',
      clusters: 'Id, ClusterName, IPs, Default',
      batches: 'id, name, supplier, date, material, author, customer',
      materials: 'id, code, name, customer',
      sensorSortStates: 'id, name, number, originalModule, moduleSerial, data'
    });
    this.version(4).stores({
      recipes: 'id, name, description, author, created, valid, customer, recipe, modified, public',
      clusters: 'Id, ClusterName, IPs, Default',
      batches: 'id, name, supplier, date, material, author, customer',
      materials: 'id, code, name, customer',
      sensorSortStates: 'id, name, number, originalModule, moduleSerial, data'
    });
    this.version(5).stores({
      recipes: 'id, name, description, author, created, valid, customer, recipe, modified, public',
      clusters: 'Id, ClusterName, IPs, Default',
      sensorSortStates: 'id, name, number, originalModule, moduleSerial, data',
      dms: 'id, version, dms'
    });
    this.version(6).stores({
      recipes: 'id, name, description, author, created, valid, customer, recipe, modified, public',
      clusters: 'Id, ClusterName, IPs, Default',
      sensorSortStates: 'id, name, number, originalModule, moduleSerial, data',
      dms: 'id, no, version, dms'
    });
    this.version(7).stores({
      recipes: 'id, name, description, author, created, valid, customer, recipe, modified, public',
      clusters: 'Id, ClusterName, IPs, Default',
      sensorSortStates: 'id, name, number, originalModule, moduleSerial, data',
      dms: 'id, no, version, dms',
      offlineUser: 'id, email, pin, data'
    });
    this.version(8).stores({
      recipes: 'id, name, description, author, created, valid, customer, recipe, modified, public',
      clusters: 'Id, ClusterName, IPs, Default',
      sensorSortStates: 'id, name, number, originalModule, moduleSerial, data',
      dms: 'id, no, version, dms',
      offlineUser: 'id, email, pin, data',
      customerModules: 'id, organization, data'
    });

    this.offlineUser.count().then(counter => {
      if (counter > 0) {
        this.connectivity.OfflineModePossible = true;
      } else {
        this.connectivity.OfflineModePossible = false;
      }
    });

    this.usermanagement.newUserLogin.subscribe(this.userLoggedIn.bind(this));

  }

  private userLoggedIn(user: User) {
    this.offlineUser.filter(ex => ex.email === user.UserName).last().then(user => {
      this.usermanagement.OnOfflineUser(user);
    });
  }

  public AddOfflineUser(email: string, pin: string, data: any) {

    this.offlineUser.filter(ex => ex.email === email).last().then(user => {
      if (user) {
        // UPDATE USER
      } else {
        // ADD User
        const user = {
          id: uuidV4(),
          email: email,
          pin: pin,
          data: JSON.stringify(data)
        };

        this.offlineUser.add(user);
        this.OfflineUserRegistered.emit(true);
      }
    });

  }

  public UpdateOfflineUser(email: string, pin: string, data: any) {
    this.offlineUser.filter(ex => ex.email === email).last().then(user => {
      if (user) {
        user.email = email;
        user.pin = pin;
        user.data = JSON.stringify(data);

        this.offlineUser.put(user);
        this.OfflineUserRegistered.emit(true);
      } else {
        this.OfflineUserRegistered.emit(false);
      }
    });

  }

  public RemoveOfflineUser(email: string) {
    this.offlineUser.filter(ex => ex.email === email).last().then(user => {
      if (user) {
        this.offlineUser.delete(user.id);
      }
    });
  }

  public getOfflineUser(email: string) {
    return this.offlineUser.filter(ex => ex.email === email).last();
  }

  public saveCustomerModules(organization: string, data: any) {
    this.customerModules.filter(ex => ex.organization === organization).last().then(mdls => {
      if (mdls) {
        // DELETE FIRST
        this.deleteCustomerModules(organization);
      }

      const mdl = {
        id: uuidV4(),
        organization: organization,
        data: JSON.stringify(data)
      };
      this.customerModules.add(mdl);
    });
  }

  public getCustomerModules(organization: string) {
    return this.customerModules.filter(ex => ex.organization == organization).last();
  }

  public deleteCustomerModules(organization: string) {
    this.customerModules.filter(ex => ex.organization === organization).last().then(mdls => {
      if (mdls) {
        this.customerModules.delete(mdls.id);
      }
    });
  }

  public UpdateDMSInfo() {
    this.dms.count().then(counter => {
      this.dmsCount = counter;
      if (counter > 0) {
        this.dms.filter(ex => ex.no === counter).last().then(data => {
          if (data) {
            this.latestDMS = JSON.parse(data.dms);
          }
        });
      }

    });
  }

  public LoadDeletedRecipes(skip = 0, take = 100) {
    this.apiservice.getRecipes(skip, take, true).subscribe((recipes: any) => {
      this.allGlobalRecipes = this.allGlobalRecipes.concat(recipes);
      if (recipes.length < take) {
        this.HandleLoadedRecipes();
      } else {
        this.LoadDeletedRecipes(skip + take, take);

      }
    }, error => {
      this.ApiRecipesLoadingError.emit();
    });
  }

  public LoadNextRecipesFromApi(skip = 0, take = 100) {
    this.apiservice.getRecipes(skip, take, false).subscribe((recipes: any) => {
      this.globalRecipes = this.globalRecipes.concat(recipes);
      if (recipes.length < take) {
        this.LoadDeletedRecipes();
      } else {
        this.LoadNextRecipesFromApi(skip + take, take);
      }
    }, error => {
      this.ApiRecipesLoadingError.emit();
    });

  }

  public async HandleLoadedRecipes() {
    if (this.allGlobalRecipes.length > 0) {
      // GET ONLY DELETED
      for (const rec of this.allGlobalRecipes) {
        const exists = this.globalRecipes.find(ex => ex.id === rec.id);
        if (!exists) {
          // TO DELETE
          let localrec = await this.recipes.filter(ex => ex.id === rec.id).first();
          if (localrec) {
            this.DeleteRecipie(localrec.id, true);
          }
        }

      }
    }

    if (this.globalRecipes.length > 0) {
      let i = 1;
      for (const recipe of this.globalRecipes) {
        if (recipe.id) {
          const localRecipes = await this.recipes.filter(ex => ex.id === recipe.id).toArray();

          if (localRecipes.length > 0) {
            if (moment(localRecipes[0].modified).isBefore(moment(recipe.modified))) {
              this.AddRecipeFromApi(recipe, i >= this.globalRecipes.length);
            } else {
              if (localRecipes[0].public !== true) {
                // ADD AS PUBLIC
                const lc = localRecipes[0];
                lc.public = true;
                this.recipes.put(lc);
              }


            }
          } else {
            this.AddRecipeFromApi(recipe, i >= this.globalRecipes.length);
          }
        }

        i = i + 1;
      }
    }

    this.LoadMissingRecipesToApi();
    this.ApiRecipesLoaded.emit();

  }

  public async UpdateRecipesFromAPI(syncDeleted = false) {
    // GET NEW RECIPES
    if (!this.connectivity.inOfflineMode) {
      this.globalRecipes = [];
      this.allGlobalRecipes = [];
      let skip = 0;
      const take = 100;
      this.LoadNextRecipesFromApi(skip, take);
    }
  }

  private async LoadMissingRecipesToApi() {
    const localRecipes = await this.recipes.toArray();

    for (const r of localRecipes.filter(ex => ex.customer == this.usermanagement.currentUser.CustomerId)) {

      if (r.name !== 'temp') {
        const gl = this.globalRecipes.find(ex => ex.id === r.id);

        if (!gl) {

          const recipeData = {
            id: r.id,
            name: r.name,
            description: r.description,
            modified: r.modified,
            created: r.created,
            data: JSON.parse(r.recipe)
          };

          // add recipe to api
          this.apiservice.AddNewRecipe(recipeData).subscribe((data: any) => {
            // UPDATE API RECIPES
            // this.UpdateRecipesFromAPI();
            r.public = true;
            this.recipes.put(r);
            this.globalRecipes.push(data);

          }, (error) => {
            console.error(error);
          });

        }

      }

    }

  }

  private AddRecipeFromApi(recipe: any, last: boolean) {

    this.apiservice.getRecipesContent(recipe.id).subscribe((recipedata: any) => {
      if (recipedata.tree) {
        const recipeDataString = JSON.stringify(recipedata);
        // ADD TO DB
        const savevalue = {
          id: recipe.id,
          name: recipe.name,
          description: recipe.description,
          customer: recipe.organization.id,
          author: recipe.author?.id ?? null,
          created: recipe.modified,
          modified: recipe.modified,
          public: true,
          valid: false,
          recipe: recipeDataString
        };
        this.AddNewRecipe(savevalue, false);
      }

      // if (last) {
      //  this.ApiRecipesLoaded.emit();
      // }


    }, error => {
      this.ApiRecipesLoadingError.emit();
    });
  }

  public DeleteRecipie(id: string, localOnly = false) {
    // GET RECIPE
    this.recipes.delete(id);
    if (this.globalRecipes && !this.connectivity.inOfflineMode && localOnly === false) {
      if (this.globalRecipes.find(ex => ex.id === id)) {
        this.apiservice.DeleteRecipe(id).subscribe((data: any) => {
        }, error => {
          console.error(error);
        });
      }
    }
  }

  public async AddNewRecipe(newRecipe: any, apiSync = true): Promise<any> {
    if (newRecipe !== undefined && newRecipe !== null) {
      if (newRecipe.recipe !== undefined &&
        newRecipe.recipe !== null) {

        if (!newRecipe.created) {
          newRecipe.created = new Date();
          newRecipe.modified = new Date();
        }

        if (newRecipe.id) {

          if (apiSync === true) {
            newRecipe.modified = new Date();
          }

          // CHECK IF EXISTS WITH NAME AND DATE
          const existings = await this.recipes.filter(ex => ex.name === newRecipe.name && ex.id !== newRecipe.id).toArray();
          if (existings && existings.length > 0) {
            for (const recs of existings) {
              const mdfNew = newRecipe.modified as Date;
              const mdfExt = recs.modified as Date;
              if (moment(mdfNew).format('YYYY-MM-DD') === moment(mdfExt).format('YYYY-MM-DD')) {
                this.DeleteRecipie(recs.id);
              }
            }
          }

          const alreadyExisting = await this.recipes.filter(ex => ex.name === newRecipe.name && ex.id !== newRecipe.id).toArray();

          if (alreadyExisting.length > 3) {
            const sorted = alreadyExisting.sort(ex => ex.created);

            for (let i = 3; i < sorted.length; i++) {
              this.DeleteRecipie(sorted[i].id);
            }
          }


          // CHECK IF NAME CHANGED
          const fnd = await this.recipes.filter(ex => ex.id === newRecipe.id).toArray();

          if (fnd.length > 0) {
            const first = fnd[0];

            if (first.name === newRecipe.name) {
              newRecipe.version++;
              newRecipe.created = new Date();
              this.recipes.put(newRecipe);
            } else {

              if (apiSync) {
                newRecipe.id = uuidV4();
                newRecipe.created = new Date();
              }
              this.recipes.add(newRecipe);
            }

          } else {
            if (apiSync) {
              newRecipe.id = uuidV4();
              newRecipe.created = new Date();
            }
            this.recipes.add(newRecipe);
          }

          newRecipe.version++;
          if (!newRecipe.created) {
            newRecipe.created = new Date();
          }
          this.recipes.put(newRecipe);

          if (apiSync && !newRecipe.tempSaved && newRecipe.name !== 'temp') {
            const existing = this.globalRecipes.find(ex => ex.id === newRecipe.id);

            if (existing) {
              // PUT
              const recipeData = {
                id: newRecipe.id,
                name: newRecipe.name,
                description: newRecipe.description,
                modified: newRecipe.modified,
                created: newRecipe.created,
                data: JSON.parse(newRecipe.recipe)
              };

              if (!this.connectivity.inOfflineMode) {
                this.apiservice.UpdateRecipe(recipeData).subscribe(() => {
                  // UPDATE API RECIPES
                  // this.UpdateRecipesFromAPI();
                }, (error) => {
                  console.error(error);
                });
              }
            } else {
              // POST
              const recipeData = {
                id: newRecipe.id,
                name: newRecipe.name,
                description: newRecipe.description,
                modified: newRecipe.modified,
                created: newRecipe.created,
                data: JSON.parse(newRecipe.recipe)
              };

              // CHECK IF EXISTS
              if (!this.connectivity.inOfflineMode) {
                this.apiservice.getRecipesContent(recipeData.id).subscribe((content: any) => {
                  this.apiservice.UpdateRecipe(recipeData).subscribe(() => {
                    // UPDATE API RECIPES
                    // this.UpdateRecipesFromAPI();
                  }, (error) => {
                    console.error(error);
                  });

                }, error => {
                  this.apiservice.AddNewRecipe(recipeData).subscribe(() => {
                    // UPDATE API RECIPES
                    // this.UpdateRecipesFromAPI();
                  }, (error) => {
                    console.error(error);
                  });


                });
              }
            }
          }
        } else if (newRecipe.id === undefined || newRecipe.id === null) {
          newRecipe.id = uuidV4();
          newRecipe.created = new Date();
          this.recipes.add(newRecipe);
          if (apiSync && !newRecipe.tempSaved && newRecipe.name !== 'temp') {
            const recipeData = {
              id: newRecipe.id,
              name: newRecipe.name,
              description: newRecipe.description,
              modified: newRecipe.modified,
              created: newRecipe.created,
              data: JSON.parse(newRecipe.recipe)
            };
            if (!this.connectivity.inOfflineMode) {
              this.apiservice.AddNewRecipe(recipeData).subscribe(() => {
                // this.UpdateRecipesFromAPI();
              }, (error) => {
                console.error(error);
              });
            }
          }
        }
        return newRecipe.id;
      }
    }
    return false;
  }

  public AddSortState(state: SensorSortState): boolean {
    if (state !== undefined && state !== null) {
      const dupl = this.sensorSortStates.filter(ex => ex.moduleSerial === state.moduleSerial && ex.number === state.number);


      dupl.first().then((data) => {
        if (data) {
          state.id = data.id;
          state.name = data.name;
          this.sensorSortStates.put(state);
        } else {
          // NEW
          if (!state.id) {
            state.id = uuidV4();
          }
          this.sensorSortStates.add(state);
        }
      });
    }
    return false;
  }

  public AddNewDMS(dms: any): boolean {
    if (dms) {
      if (dms.Info) {
        if (dms.Info.Version) {
          const version = dms.Info.Version;
          const dupl = this.dms.filter(ex => ex.version === version);

          dupl.count().then(ct => {
            if (ct <= 0) {
              const dd = {
                id: uuidV4(),
                version: version,
                no: 1,
                dms: JSON.stringify(dms)
              };
              this.dms.put(dd);
              this.UpdateDMSInfo();
            } else {
              dupl.first().then(data => {
                if (!data) {
                  // ADD
                  const dd = {
                    id: uuidV4(),
                    no: ct + 1,
                    version: version,
                    dms: JSON.stringify(dms)
                  };
                  this.dms.put(dd);
                  this.UpdateDMSInfo();
                }
              });
            }
          });
          return true;
        }
      }
    }
    return false;
  }

}
