<div class="main" fxLayout="column">
  <mat-dialog-content fxLayout="column" fxFlexAlign="stretch">
    <h2><strong>{{'MESSAGEBOX.HEADERS.RECIPENOTVALID' | i18next }}</strong></h2>
    <p class="content">{{'MESSAGEBOX.CONTENT.NOTVALIDFORLIVE' | i18next }}</p>
    <div fxLayout="column"  fxFlexAlign="center" class="downscrollarrow flipArrow">
      <button mat-button class="arrowbtn" (click)="ScrollUp()" *ngIf="UpScrolling()">
        <img src="/assets/general/arrow_scroll.svg">
      </button>
    </div>
    <div class="seriallist" #validationContainer>
      <mat-list *ngFor="let val of ValidationInfo" #validationList>
        <div fxLayout="row" fxFlexAlign="center">
          <p class="headerElement">{{val.module}}</p>
          <p class="textElement">{{val.text}}</p>
        </div>
      </mat-list>
    </div>
    <div fxLayout="column"  fxFlexAlign="center" class="downscrollarrow">
      <button mat-button class="arrowbtn" (click)="ScrollDown()" *ngIf="DownScrolling()">
        <img src="/assets/general/arrow_scroll.svg">
      </button>
    </div>
  </mat-dialog-content>
  <div class="buttondiv" fxLayout="row" fxFlexAlign="stretch">
    <button class="yesButton" fxFlexAlign="flex-start" (click)="Confirm()">{{'COMMON.OK' | i18next }}</button>
  </div>
</div>
