<section fxLayout="column" fxFlexAlign="stretch" class="uploadWrapper">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 fxFlexAlign="center" class="recipeUpload" mat-dialog-title>{{'DIALOGS.UPLOADRECIPE.TITLE' | i18next}}</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <!-- <button mat-button (click)="CloseDialog()">
        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
          <g>
            <rect height="32" width="32" fill="none" />
            <path id="path1" transform="rotate(0,16,16) translate(0.00249958053228738,0) scale(1.00000005960465,1.00000005960465)  " d="M29.590818,0C30.206963,0 30.823227,0.23449993 31.292229,0.70349979 32.229256,1.6405001 32.229256,3.1605005 31.292229,4.1004992L19.398003,15.998498 31.292229,27.892495C32.229256,28.829499 32.229256,30.349496 31.292229,31.294496 30.354227,32.2315 28.827164,32.2315 27.890139,31.294496L15.997988,19.399499 4.1047992,31.296502C3.1677734,32.234498 1.6397347,32.234498 0.70276969,31.296502 -0.23425656,30.352502 -0.23425656,28.832498 0.70276969,27.894502L12.596934,15.998498 0.70276969,4.1024981C-0.23425656,3.1654978 -0.23425656,1.6454973 0.70276969,0.7054987 1.6397347,-0.23150158 3.1677734,-0.23150158 4.1047992,0.7054987L15.99695,12.597497 27.890139,0.70349979C28.35865,0.23449993,28.974674,0,29.590818,0z" />
          </g>
        </svg>
      </button> -->
    </div>
  </div>

  <div class="uploadline" fxLayout="column" fxFlexAlign="stretch">
      <div class="buttondiv" fxLayoutAlign="end" fxFlexAlign="right" *ngIf="!UploadActive">
        <div class="uploadButtons blue" (click)="UploadRecipe()">
        <p class="surpre">{{'DIALOGS.UPLOADRECIPE.NOW' | i18next}}</p>
        <p class="main">{{'DIALOGS.UPLOADRECIPE.UPLOAD' | i18next}}</p>
        <p class="surpre">{{'DIALOGS.UPLOADRECIPE.RUN' | i18next}}</p>

          <!-- <img src="/assets/general/button_blue.svg" > -->
          </div>
      <div class="uploadButtons red" (click)="CloseDialog()">
        <p class="surpre" style="padding-top: 14px;"></p>
        <p class="main">{{'DIALOGS.UPLOADRECIPE.UPLOAD' | i18next}}</p>
        <p class="surpre">{{'DIALOGS.UPLOADRECIPE.CANCEL' | i18next}}</p>
        <!-- <img src="/assets/general/button_red.svg"> -->
      </div>

      <!-- <button mat-button (click)="UploadRecipe()">
        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48">
          <title>{{'DIALOGS.UPLOADRECIPE.TITLE' | i18next}}</title>
          <g>
            <path id="path1" transform="rotate(0,24,24) translate(0,8.25) scale(1.5,1.5)  " d="M0,18.899998L32,18.899998 32,21 0,21z M4.5999756,8.5L8.2000122,8.5 16,15 23.799988,8.5 27.400024,8.5 32,17.299999 0,17.299999z M13.700012,0L18.299988,0 18.299988,7.2000008 22.900024,7.2000008 16,12.899998 9.0999756,7.2000008 13.700012,7.2000008z" />
          </g>
        </svg>
      </button> -->
    </div>

      <div *ngIf="UploadActive" fxLayout="column" fxFlexAlign="stretch">
        <div class="tablediv" *ngIf="UploadInfo.length > 0">
          <table class="infotable" fxLayout="column" *ngFor="let inf of UploadInfo">
            <tr  class="elementRow" fxLayout="row" fxFlexAlign="stretch">
              <td style="width: 100%" class="elementText"><p class="contentp">{{inf}}</p></td>
            </tr>
          </table>
        </div>

        <div class="loadSpinner" fxFlexAlign="center" *ngIf="UploadInfo.length <= 0">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
      </div>

      <div *ngIf="ErrorText">
        <p class="error">{{ErrorText}}</p>
      </div>

      <div class="tablediv" *ngIf="UpdateInfo.length > 0">
        <table class="infotable" fxLayout="column" *ngFor="let inf of UpdateInfo">
          <tr  class="elementRow" fxLayout="row" fxFlexAlign="stretch">
            <td style="width: 100%" class="elementText"><p class="contentpInfo">{{inf}}</p></td>
          </tr>
        </table>
      </div>

      <div *ngIf="UpdateSucessfull">
        <p>{{'DIALOGS.UPLOADRECIPE.SUCCESS' | i18next}}</p>
      </div>
  </div>

</section>
