<section [ngStyle]="{'top': _top, 'left': left, 'width': _widht, 'height': _height, 'position': position}">
  <div class="senke" *ngIf="SinkConnected() && (moduleHandling.CurrentViewMode === 0 || conveyor.Conntected === true)" (click)="OnSetConnection()" [ngStyle]="{'top':sinkTop, 'right': sinkRight, 'transform': sinkScale}">
    <img src="/assets/senke_bearbeitungsmodus.svg" style="position: relative; max-height: 100%; max-width: 100%;">
  </div>
  <div class="senke" *ngIf="SinkConnected() && moduleHandling.CurrentViewMode === 1 && conveyor.Conntected === false" (click)="OnSetConnection()" [ngStyle]="{'top':sinkTop, 'right': sinkRight, 'transform': sinkScale}">
    <img src="/assets/senke_realer_modus.svg" style="position: relative; max-height: 100%; max-width: 100%;">
  </div>

  <div (click)="clickedOnConveyor.emit()" class="sx" [ngStyle]="{'top': 0, 'left': 0, 'width': '100%', 'height': '100%', 'position': position, 'background-image': _backgroundImage}"></div>

  <button mat-button *ngIf="isNotConnected() && moduleHandling.CurrentViewMode === 0" class="unconnected" (click)="OnSetConnection()">
    <img src="/assets/general/plus_icon.svg" style="position: relative; max-height: 100%; max-width: 100%;">
  </button>
  <button mat-button *ngIf="isConnectionMissing() && moduleHandling.CurrentViewMode === 0" class="unconnected" [ngStyle]="{'transform': rotateQuest}">
    <img src="/assets/general/missingConnection_icon.svg" style="position: relative; max-height: 100%; max-width: 100%;">
  </button>
  <button mat-button *ngIf="ChangeConveyorAllowedConnected() && moduleHandling.CurrentViewMode === 0  && false" class="changelength"  [ngClass]="{'changelength': conveyor.LenghtType == 0, 'changelengthShort': conveyor.LenghtType == 1}" (click)="ChangeLength()"  [ngStyle]="{'transform': rotateQuest}">
     <img class="invertedWhite" src="/assets/general/arrows.svg">
  </button>
  <div class="magnet" *ngIf="conveyor.LenghtType == 2 && (moduleHandling.CurrentViewMode === 0)" (click)="OnSetConnection()" [ngStyle]="{'top':magnetTop, 'right': magnetRight, 'transform': sinkScale}">
    <img src="/assets/MagnetDesign.svg" style="position: relative; max-height: 100%; max-width: 100%;" [ngStyle]="{'transform': getMagnetRotation()}" (click)="changeMagnetDirection($event)">
  </div>
  <div class="magnet" *ngIf="conveyor.LenghtType == 2 && (moduleHandling.CurrentViewMode === 1)" (click)="OnSetConnection()" [ngStyle]="{'top':magnetTop, 'right': magnetRight, 'transform': sinkScale}">
    <img src="/assets/MagnetLive.svg" style="position: relative; max-height: 100%; max-width: 100%;" [ngStyle]="{'transform': getMagnetRotation()}" (click)="changeMagnetDirection($event)">
  </div>
</section>
