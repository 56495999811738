import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EndPointTypes} from '../../../../classes/enums/end-point-types.enum';
import {ModuleRotations} from '../../../../classes/enums/module-rotations.enum';
import {Conveyor} from '../../../../classes/conveyor';
import {ModuleConnections} from '../../../../classes/enums/module-connections.enum';
import {ModuleHandlingService} from '../../../../services/module-handling.service';
import {ViewCode} from '../../../../classes/enums/view-code.enum';
import {ConveyorLenghtType} from '../../../../classes/enums/conveyor-lenght-type.enum';

@Component({
  selector: 'app-design-conveyor90deg',
  templateUrl: './design-conveyor90deg.component.html',
  styleUrls: ['./design-conveyor90deg.component.css']
})
export class DesignConveyor90degComponent implements OnInit {

  _widht = '225.25px';
  _height = '45px';
  _hightValue = 45;
  _widthValue = 225.25;
  _top = '20px';
  _left = '45px';
  _backgroundImage = 'url("/assets/p_foerderband_80.svg")';
  public _conveyor: Conveyor;
  public transform = 'scale(1,1)';
  public transformText = 'scale(1,1)';
  public viewport = '0 0 41.66 116.67';
  public texty = '0';
  public textx = '0';
  public rectHeight = '111.67';
  public rectWidht = '41.6667';
  rotateQuest = 'rotate(0deg)';
  private rotateText = 'rotate(0deg)';
  sinkScale = 'scale(1,1)';
  sinkRight = '-40px';
  sinkTop = 'calc(50% - 40px)';

  magnetRight = 'calc(225.25 - 100px)';
  magnetTop = 'calc(50% - 22px)';

  public position = 'absolute';

  @Output() changedConveyor = new EventEmitter<Conveyor>();
  @Output() changedSize: EventEmitter<Conveyor> = new EventEmitter<Conveyor>();
  @Output() changeConnection: EventEmitter<ModuleConnections> = new EventEmitter<ModuleConnections>();
  @Output() clickedOnConveyor = new EventEmitter();
  @Input() connectedToModule = '';
  @Input() set left(value: string) {

    const lf = value.replace('px', '');
    const vv = parseFloat(lf);
    this._left = (vv + this._hightValue) + 'px';
  }
  @Input() set top(value: string) {
    this._top = value;
  }
  get left() {
    return this._left;
  }
  @Input() set conveyor(value: Conveyor) {
    this._conveyor = value;

    if (this._conveyor) {
      this._conveyor.OnlineStateChanged.subscribe(this.conveyorOnlineStateChanged.bind(this));
    }

    if (this._conveyor.Lenght === 60) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/assets/foerderband_lang_design_mode_60.svg")';
        } else {
          this._backgroundImage = 'url("/assets/foerderband_kurz_design_mode_60.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_active_60.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_active_60.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_60.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_60.svg")';
          }
        }



      }
    } else if (this._conveyor.Lenght === 110) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/assets/foerderband_lang_design_mode_110.svg")';
        } else {
          this._backgroundImage = 'url("/assets/foerderband_kurz_design_mode_110.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_active_110.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_active_110.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_110.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_110.svg")';
          }
        }



      }
    } else {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/assets/foerderband_lang_design_mode.svg")';
        } else {
          this._backgroundImage = 'url("/assets/foerderband_kurz_design_mode.svg")';
        }
      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_active.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_active.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode.svg")';
          }
        }
      }
    }

    this.UpdateView();
  }
  get conveyor(): Conveyor {
    return this._conveyor;
  }
  @Input() set size(value: number) {

    let lengthFactor = 1.0;

    if (this.conveyor.LenghtType === ConveyorLenghtType.short) {
      lengthFactor = 2.0;
    }

    this._widht = ((4505 / value) / lengthFactor) + 'px';
    this._height = (((900) / value)) + 'px';
    this._hightValue = (900) / value;
    this._widthValue = (4505 / value) / lengthFactor;

    // SCALE
    const scale = (4505 / value) / 225.25;

    this.sinkScale = 'scale(' + scale + ', ' + scale + ')';
    this.sinkRight = ( - 50) + 'px';
    this.sinkTop = 'calc(50% - ' + (40 * scale) + 'px)';
    this.magnetTop = 'calc(50% - ' + (22 * scale) + 'px)';
    this.magnetRight = 'calc(' + (this._widthValue) + 'px - ' + (100 * scale) + 'px)';

  }
  @Input() isSelected = true;
  @Input() modulerotation: ModuleRotations = ModuleRotations.degree_0;


  constructor(public moduleHandling: ModuleHandlingService) { }

  ngOnInit() {
  }

  private conveyorOnlineStateChanged() {
    if (this._conveyor.Lenght === 60) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/assets/foerderband_lang_design_mode_60.svg")';
        } else {
          this._backgroundImage = 'url("/assets/foerderband_kurz_design_mode_60.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_active_60.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_active_60.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_60.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_60.svg")';
          }
        }



      }
    } else if (this._conveyor.Lenght === 110) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/assets/foerderband_lang_design_mode_110.svg")';
        } else {
          this._backgroundImage = 'url("/assets/foerderband_kurz_design_mode_110.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_active_110.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_active_110.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_110.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_110.svg")';
          }
        }



      }
    } else {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/assets/foerderband_lang_design_mode.svg")';
        } else {
          this._backgroundImage = 'url("/assets/foerderband_kurz_design_mode.svg")';
        }
      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode_active.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode_active.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/assets/foerderband_lang_live_mode.svg")';
          } else {
            this._backgroundImage = 'url("/assets/foerderband_kurz_live_mode.svg")';
          }
        }
      }
    }

    this.UpdateView();
  }

  public UpdateView() {
    let v2 = this._hightValue / 400;
    v2 = v2 / 0.416666666666667;

    this.transform = 'scale(' + 1 + ',' + 1 + ')';
    this.viewport = '0 0 ' + this._widthValue + ' ' + this._hightValue;
    this.textx = '' + (5 * v2) + '';
    this.rectWidht = '' + (this._widthValue - 5) + '';
    this.rectHeight = '' + (this._hightValue - 5) + '';
    this.texty = '' + ((this._hightValue - 5) / 2) + '';
    this.rotateQuest = 'rotate(-' + this.modulerotation + 'deg)';

    if (this.modulerotation === ModuleRotations.degree_0 || this.modulerotation === ModuleRotations.degree_90) {


      this.transformText = 'scale(' + 1 + ',' + 1 + ')';

      this.rotateText = 'rotate(0deg)';
    } else {

      this.transformText = 'scale(-' + 1 + ',-' + 1 + ') translate(-' + this._widthValue + ',-' + (this._hightValue) + ')';

      this.rotateText = 'rotate(180deg)';
    }
  }

  isNotConnected(): boolean {
      return this.conveyor.EndPointType === EndPointTypes.undefined && this.isSelected;
    }

  isSink(): boolean {
    return this.conveyor.EndPointType === EndPointTypes.sink;
  }

  OnSetConnection() {
    if (this.isSelected) {
      this.moduleHandling.ConveyorConnectionPointClicked(this.conveyor);
    }
  }

  isConnectionMissing(): boolean {
    return this.conveyor.EndPointType === EndPointTypes.undefined && !this.isSelected;
  }

  public SinkConnected(): boolean {
    return this.conveyor.EndPointType === EndPointTypes.sink;
  }

  isConnected(): boolean {

    if (this.isSelected) {
      if (this.conveyor.EndPointType !== EndPointTypes.undefined) {
        return true;
      }
    }

    return false;

  }

  public ChangeConveyorAllowedConnected(): boolean {

    if (this.isSelected) {
      if (this.conveyor.EndPointType !== EndPointTypes.module) {
        return true;
      }
    }

    return false;
  }

  public ChangeLength() {
    this.changedSize.emit(this.conveyor);
  }

  public changeMagnetDirection($event) {


    $event.stopPropagation();

    // GET IT
    const currentValue = this.getCurrentMagnetDirection();
    // SET IT
    if (currentValue === false) {
      this.moduleHandling.setConveyorAddOnValueForMagnet(this.conveyor, this.connectedToModule, true);
    } else {
      this.moduleHandling.setConveyorAddOnValueForMagnet(this.conveyor, this.connectedToModule, false);
    }


  }

  public getMagnetRotation(): String {
    return  'rotate(' + (this.getCurrentMagnetDirection() === false ? '-90deg' : '90deg')
      + ')';
  }

  public getCurrentMagnetDirection(): any {
    return this.moduleHandling.GetConveyorAddOnValueForMagnet(this.conveyor, this.connectedToModule);
  }

}
