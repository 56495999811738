<section fxLayout="row" [ngClass]="{'darkTheme': (moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode  === 3) && _modulePlan.modul.CurrentMode !== 'ServiceMode', 'grayTheme': moduleHandling.CurrentViewMode  === 1 && _modulePlan.modul.CurrentMode !== 'ServiceMode', 'serviceTheme': _modulePlan.modul.CurrentMode === 'ServiceMode' || moduleHandling.CurrentViewMode === 3}">

  <div class="versionDiv" *ngIf="moduleHandling.ActiveModule && moduleHandling.ActiveModule.modul.NetworkInfo">
    <p>{{'COMPONENTS.MODULESETTINGS.NODEVERSION' | i18next }} {{moduleHandling.ActiveModule.modul.NetworkInfo.nodeversion}}</p>
    <p>{{'COMPONENTS.MODULESETTINGS.PLCVERSION' | i18next }} {{moduleHandling.ActiveModule.modul.NetworkInfo.plcversion}}</p>
    <p>{{'COMPONENTS.MODULESETTINGS.SPECVERSION' | i18next }} {{moduleHandling.ActiveModule.modul.NetworkInfo.specversion}}</p>
  </div>

  <div class="moduleimage" fxLayout="column" fxFlexAlign="bottom" (click)="ClickOnObjectPlate()">
    <div class="header" fxLayout="row"  fxFlexAlign="left">
      <div fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
        <button mat-button (click)="SignalTest()" *ngIf="(moduleHandling.CurrentViewMode === 1 ||
         moduleHandling.CurrentViewMode === 3) && customerModule && customerModule.SerialNumberSetted">

        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
            <g>
              <path id="path1" d="M15.799988,8.6000042C17.600037,8.6000042 19.100037,10.100003 19.100037,11.900006 19.100037,13.699993 17.600037,15.199993 15.799988,15.199993 14,15.199993 12.5,13.699993 12.5,11.900006 12.5,9.9999972 14,8.6000042 15.799988,8.6000042z M22.400024,4.6999956C24.400024,6.4000078 25.700012,8.9999981 25.700012,11.8 25.700012,14.699993 24.400024,17.299998 22.299988,18.999995L20,16.900004C21.5,15.699993 22.5,13.900005 22.5,11.8 22.5,9.9000063 21.600037,8.1000042 20.200012,6.9000073z M9.6000366,4.6999956L11.700012,6.9999981C10.299988,8.1999951 9.4000244,9.9000063 9.4000244,11.900006 9.4000244,13.999996 10.400024,15.799999 11.900024,16.999995L9.7000122,19.199992C7.6000366,17.499995 6.2999878,14.900005 6.2999878,11.999997 6.4000244,8.9999981 7.6000366,6.4000078 9.6000366,4.6999956z M26.600037,0C29.900024,2.9000085 32,7.1000042 32,11.8 32,16.499995 30,20.600001 26.799988,23.499995L24.600037,21.299998C27.299988,18.999995 28.900024,15.600002 28.900024,11.699994 28.900024,7.9000073 27.200012,4.4999991 24.5,2.1000057z M5.4000244,0L7.5,2.3000026C4.7999878,4.6000052 3.1000366,7.9999981 3.1000366,11.8 3.1000366,15.600002 4.7999878,18.999995 7.4000244,21.400004L5.2000122,23.600001C2,20.699992 0,16.499995 0,11.8 0,7.1000042 2.1000366,2.9000085 5.4000244,0" />
            </g>
          </svg>
        </button>
      </div>
    </div>
    <button class="uploadbutton" *ngIf="ServiceUploadPossible()" (click)="UploadService()">
      <div fxLayout="column" class="startbuttondiv">
        <p class="main" class="centerTextVertical">{{'COMPONENTS.MODULESETTINGS.UPLOAD' | i18next}}</p>
      </div>
    </button>
    <button class="startbutton" *ngIf="ShowStartButton()" (click)="ShowStartDialog()">
      <div fxLayout="column" class="startbuttondiv">
        <p class="surpre">{{'COMPONENTS.MODULESETTINGS.START.SURFIX' | i18next}}</p>
        <p class="main">{{'COMPONENTS.MODULESETTINGS.START.MAIN' | i18next}}</p>
        <p class="surpre">{{'COMPONENTS.MODULESETTINGS.START.PREFIX' | i18next}}</p>
      </div>
    </button>
    <div fxFlexAlign="right" class="testimg">
      <img #moduleImage class="responsivePaths" src="{{_modulePlan.modul.PathDetailImage}}" alt="/assets/ModulDetail/NoModuleCut.png">
      <div #moduleComponents class="responsivePaths2"></div>
    </div>
  </div>

  <div class="settings" fxLayout="row" [ngClass]="{'livesettings': moduleHandling.CurrentViewMode === 1, 'serviceSettings': _modulePlan.modul.CurrentMode === 'ServiceMode' || moduleHandling.CurrentViewMode === 3}" >
    <!-- <div class="line" [ngClass]="{'linegray': moduleHandling.CurrentViewMode === 1}" (click)="ClickOnLine()"></div> -->
    <div class="content" fxLayout="column" fxFlexAlign="center" *ngIf="settingWidth !== '30px'">
      <div class="header" fxLayout="row"  fxFlexAlign="left">
        <p *ngIf="_modulePlan.modul.CurrentMode !== 'ServiceMode'; else elseblock" class="headerp" [ngClass]="{'headerp': router.url !== '/live', 'headerpBlack': router.url === '/live'}">{{'COMPONENTS.MODULESETTINGS.TITLE' | i18next }}</p>
        <ng-template #elseblock>
          <p class="headerp">{{'COMPONENTS.MODULESETTINGS.TITLESERVICE' | i18next }}</p>
        </ng-template>
        <div fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center" class="downscrollarrow" style="position: absolute; right: 32px;">


          <div fxLayout="row">
            <button mat-button class="closeSettingsButton" (click)="SaveConfig()" *ngIf="ShowConfigurationChanged()">
              <img src="/assets/general/save.svg" style="width: 32px; margin-left: 15px;" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
            </button>
            <button mat-button class="closeSettingsButton" (click)="LoadCurrentConfig()" *ngIf="LoadConfigPossible()">
              <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32"  [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
                <title>{{'COMPONENTS.MODULESETTINGS.LOADMODULERECIPE' | i18next }}</title>
                <g>
                  <path id="path1" transform="rotate(0,16,16) translate(1,5.88812744617462) scale(0.9375,0.9375)  " d="M0,18.834001L32,18.834001 32,21.571994 0,21.571994z M13.120996,0L18.562037,0 18.562037,8.1130029 18.573023,8.1130029 18.573023,8.1189843 24.087,8.1669885 15.70199,16.402 7.4619999,8.0249902 12.979029,8.0719872 12.979029,8.0649987 13.120996,8.0669824z" />
                </g>
              </svg>
            </button>
            <button mat-button class="closeSettingsButton" (click)="moduleHandling.CloseModuleSettings()">
              <img src="/assets/general/close.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!customerModule">
        <p class="notancustomermodule">{{'COMPONENTS.MODULESETTINGS.NOCUSTOMERMODULE' | i18next }}</p>
      </div>
      <!-- CATEGORIES !-->
      <mat-tab-group (selectedTabChange)="onTabChanged($event)" class="viewModuleSettings">
        <mat-tab id="parameters" *ngIf="ShowParameterTab()"  (change)="SelectParameterTab()" class="tabLabel" [ngClass]="{'labelDark': router.url !== '/live', 'labelLight': router.url === '/live'}" label="{{'COMPONENTS.MODULESETTINGS.LABELS.PARAMETER' | i18next }}" class="configTab" [ngClass]="{'configTab': router.url !== '/live', 'configTabBlack': router.url === '/live'}">
          <div fxLayout="column"
               #deviceConfigElements2
               (mousewheel)="MouseWheelRotation($event)"
               (panup)="movePanUp($event)"
               (pandown)="movePanUp($event)"
               (tap)="panStarts($event)"
               (pan)="panStarts($event)"
               (panstart)="panStarts($event)"
               (panend)="panStarts($event)"
               class="monitoringDiv"
               [ngClass]="{'monitoringDiv': router.url !== '/live', 'monitoringDivBlack': router.url === '/live'}"
               fxFlexAlign="stretch">
            <div fxLayout="column" *ngFor="let dev of GetDevicesWithConfig()">
              <app-module-device-container-config [SelectedComponent]="selectedComponent" [device]="dev" [inventoryView]="inventoryView" [switchModeString]="(_modulePlan.modul.NetworkInfo ? _modulePlan.modul.NetworkInfo.switchstate : null)" (OnSendToModule)="SendToModule($event)" (DownloadRequested)="RequestDownload($event)" (UploadRequested)="RequestUpload($event)" (ConfigurationChanged)="ConfigrationChanged($event)"></app-module-device-container-config>
            </div>
            <p class="sectionHeadline"> {{'COMPONENTS.MODULESETTINGS.COMMENT' | i18next}} </p>
            <div *ngIf="customerModule" fxLayout="row" fxFlexAlign="stretch" class="commentDiv" [ngClass]="{'commentDivOnEdit': CommentOnEdit === true, 'commentDivWComment': CommentOnEdit === false && customerModule.Comment}">
              <textarea (focusout)="clickComment()" placeholder="{{'COMPONENTS.MODULESETTINGS.ENTERCOMMENT' | i18next}}" cols="40" rows="5" class="commentInputenabled" [(ngModel)]="customerModule.Comment">
              </textarea>
            </div>
          </div>
        </mat-tab>
        <mat-tab id="monitoring" label="{{'COMPONENTS.MODULESETTINGS.LABELS.MONITORING' | i18next }}" class="monitoringTab" *ngIf="ShowMonitoringTab()"
                 [ngClass]="{'monitoringTab': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode  === 3, 'monitoringTabBlack': moduleHandling.CurrentViewMode  === 1}">
          <div fxLayout="column"
               #deviceConfigElements
               (mousewheel)="MouseWheelRotation($event)"
               (panup)="movePanUp($event)"
               (pandown)="movePanUp($event)"
               (tap)="panStarts($event)"
               (pan)="panStarts($event)"
               (panstart)="panStarts($event)"
               (panend)="panStarts($event)"
               class="monitoringDiv"
               [ngClass]="{'monitoringDiv': router.url !== '/live', 'monitoringDivBlack': router.url === '/live'}"
               fxFlexAlign="stretch">
            <div fxLayout="column" *ngFor="let dev of GetMonitoringDevices()">
              <app-component-monitoring [device]="dev" [inventoryView]="inventoryView" [switchModeString]="(_modulePlan.modul.NetworkInfo ? _modulePlan.modul.NetworkInfo.switchstate : null)" (OnSendToModule)="SendToModule($event)"></app-component-monitoring>
            </div>
          </div>
        </mat-tab>
        <mat-tab id="service" *ngIf="ShowServiceTab()" [ngClass]="{'labelDark': router.url !== '/live', 'labelLight': router.url === '/live'}" label="{{'COMPONENTS.MODULESETTINGS.LABELS.SERVICE' | i18next }}" class="configTab tablabel">
          <div fxLayout="column"
               #deviceConfigElements2
               (mousewheel)="MouseWheelRotation($event)"
               (panup)="movePanUp($event)"
               (pandown)="movePanUp($event)"
               (tap)="panStarts($event)"
               (pan)="panStarts($event)"
               (panstart)="panStarts($event)"
               (panend)="panStarts($event)"
               class="monitoringDiv"
               [ngClass]="{'monitoringDiv': router.url !== '/live', 'monitoringDivBlack': router.url === '/live'}"
               fxFlexAlign="stretch">
            <div fxLayout="column" *ngFor="let dev of GetDevicesWithMaintance()">
              <app-component-service [SelectedComponent]="selectedComponent"
                                     [Component]="dev"
                                     (ComponentSelected)="OnSelectedComponentChanged($event)"
                                     (ServiceSelectionChanged)="OnServiceSelectionChanged($event)"
                                     (ConfigurationChanged)="ConfigrationChanged($event)"></app-component-service>
            </div>
          </div>
        </mat-tab>
        <mat-tab id="maintenance" *ngIf="ShowServiceTab()" [ngClass]="{'labelDark': router.url !== '/live', 'labelLight': router.url === '/live'}" label="{{'COMPONENTS.MODULESETTINGS.LABELS.MAINTENANCE' | i18next }}" class="configTab tablabel">
          <div fxLayout="column"
               #deviceConfigElements2
               (mousewheel)="MouseWheelRotation($event)"
               (panup)="movePanUp($event)"
               (pandown)="movePanUp($event)"
               (tap)="panStarts($event)"
               (pan)="panStarts($event)"
               (panstart)="panStarts($event)"
               (panend)="panStarts($event)"
               class="monitoringDiv"
               [ngClass]="{'monitoringDiv': router.url !== '/live', 'monitoringDivBlack': router.url === '/live'}"
               fxFlexAlign="stretch">

          </div>
        </mat-tab>
        <mat-tab id="notifications" [ngClass]="{'labelDark': router.url !== '/live', 'labelLight': router.url === '/live'}" label="{{'COMPONENTS.MODULESETTINGS.LABELS.NOTIFICATION' | i18next }}" class="messageTab" *ngIf="(moduleHandling.CurrentViewMode === 1 || moduleHandling.CurrentViewMode === 3) && this.customerModule"
                 [ngClass]="{'messageTab': router.url !== '/live', 'messageTabBlack': router.url === '/live'}">
          <div fxLayout="column"
               #deviceConfigElements
               (mousewheel)="MouseWheelRotation($event)"
               (panup)="movePanUp($event)"
               (pandown)="movePanUp($event)"
               (tap)="panStarts($event)"
               (pan)="panStarts($event)"
               (panstart)="panStarts($event)"
               (panend)="panStarts($event)"
               class="monitoringDiv"
               [ngClass]="{'monitoringDiv': router.url !== '/live', 'monitoringDivBlack': router.url === '/live'}"
               fxFlexAlign="stretch">

            <div class="contentd" fxLayout="column" fxFlexAlign="left" *ngFor="let dv of originModule.ActiveErrorNotifications">
              <app-notification-view [Notification]="dv" [Selected]="selectedComponent"></app-notification-view>
            </div>

            <div class="contentd" fxLayout="column" fxFlexAlign="left" *ngFor="let dv of originModule.ActiveWarningNotifications">
              <app-notification-view [Notification]="dv" [Selected]="selectedComponent"></app-notification-view>
            </div>

            <!--
            <div class="contentd" fxLayout="column" fxFlexAlign="left">
              <table *ngFor="let dv of originModule.ActiveErrorNotifications" class="elements" fxLayout="column">
                <tr class="elementRow" fxLayout="row" fxFlexAlign="stretch">
                  <td class="elementText"><p class="contentp">{{GetNotificationCode(dv)}}    {{dv.ComponentTranslateId | i18next}} {{GetNotificationComponentNumber(dv)}}:</p></td>
                </tr>
                <tr class="elementRowTxt" fxLayout="row" fxFlexAlign="stretch">
                  <td class="elementTextP"><p class="contentp2">{{GetNotificationText(dv)}} {{GetNotificationBmK(dv)}}</p></td>
                </tr>
              </table>
            </div>
            <div class="contentd" fxLayout="column" fxFlexAlign="left">
              <table *ngFor="let dv of originModule.ActiveWarningNotifications" class="elements" fxLayout="column">
                <tr class="elementRow" fxLayout="row" fxFlexAlign="stretch">
                <tr class="elementRow" fxLayout="row" fxFlexAlign="stretch">
                  <td style="width: 100%" class="elementText"><p class="contentp">{{GetNotificationCode(dv)}}   {{dv.ComponentTranslateId | i18next}}:</p></td>
                </tr>
                <tr class="elementRowTxt" fxLayout="row" fxFlexAlign="stretch">
                  <td style="width: calc(100% - 30px); margin-left: 30px" class="elementTextP"><p class="contentp2">{{GetNotificationText(dv)}} {{GetNotificationBmK(dv)}}</p></td>
                </tr>
              </table>
            </div>
            !-->
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>
