import {ModulePlan} from './module-plan';
import {ModuleConnections} from './enums/module-connections.enum';
import {CustomerModule} from './customer-module';
import {Batch} from './batch';
import {ModuleDefinition} from './module-definition';
import {SupportModulePlan} from './support-module-plan';

export class Project {
  name = 'temp';
  version = 1;
  author: string;
  created: Date;
  modified: Date;
  public: boolean;
  id: string;
  valid: boolean;
  batch: Batch;
  description: string;
  currentRevision = 0;
  changed = false;
  tempSaved = false;
  essentialChange = false;
  cluster: any;
  Modules: ModulePlan[];
  SupportModules: SupportModulePlan[];

  constructor(name: string) {
    if (name) {
      this.name = name;
    }
    this.Modules = [];
  }

  addFirstModule(module?: ModuleDefinition, customerModule?: CustomerModule): void {
    this.changed = true;
    this.essentialChange = true;
    if (this.Modules.length <= 0) {
      this.Modules.push(new ModulePlan(true, module , customerModule));
    }
  }

  addConveyor(conveyor: any, addTo: ModuleDefinition, connectionPoint: ModuleConnections) {
    console.log(conveyor);
    this.changed = true;
    this.essentialChange = true;
    const toModule = this.Modules.find(x => x.modul as ModuleDefinition === addTo);

    if (toModule) {
      toModule.addConntection(conveyor, connectionPoint);
    }
  }
}
