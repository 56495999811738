import {Component, Input, OnInit} from '@angular/core';
import {ModuleOnboardingComponent} from '../../views/module-onboarding/module-onboarding.component';

@Component({
  selector: 'app-handsover-points-tab',
  templateUrl: './handsover-points-tab.component.html',
  styleUrls: ['./handsover-points-tab.component.css']
})
export class HandsoverPointsTabComponent implements OnInit {

  @Input() onboardingComp: ModuleOnboardingComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
