import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {LoadStates} from '../../../classes/enums/load-states.enum';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {UserManagementService} from '../../../services/user-management.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {DatabaseService} from '../../../services/database.service';
import {ConfirmMessageBoxComponent} from '../confirm-message-box/confirm-message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MessageBoxComponent} from '../message-box/message-box.component';
import {ConnectivityService} from '../../../services/connectivity.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit, AfterViewInit {

  public currentState: LoadStates = LoadStates.INIT;
  public isReload = false;
  public retryCounter = 0;
  public DMSLoaded = false;
  public UserInfoLoaded = false;
  public CustomerModulesLoaded = false;
  public RecipesLoadedFlag = false;
  private alreadyClosed = false;

  constructor( private dialogRef: MatDialogRef<SplashScreenComponent>,
               @Inject(MAT_DIALOG_DATA) data,
               public bluectrlapi: BluectrlApiService,
               public usermanagement: UserManagementService,
               public servermanagement: ServerCommunicationService,
               public translate: BluectrlTranslateService,
               public moduleHandling: ModuleHandlingService,
               private connectivity: ConnectivityService,
               public dialog: MatDialog,
               public database: DatabaseService) {
    this.isReload = data as boolean;

    this.servermanagement.OnModulesLoaded.subscribe(this.OnDmsLoaded.bind(this));
    this.servermanagement.OnModulesLoadedError.subscribe(this.OnDmsLoadedError.bind(this));

    this.database.ApiRecipesLoaded.subscribe(this.RecipesLoaded.bind(this));
    this.database.ApiRecipesLoadingError.subscribe(this.RecipesLodedError.bind(this));

    this.retryCounter = 0;
    this.alreadyClosed = false;

    if (this.servermanagement.GetModules().length <= 0) {
      this.isReload = true;
    }

    if (this.isReload) {
      this.LoadDMS();
    } else {
      this.DMSLoaded = true;
      this.GetUserInfo();
    }


  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

  }

  public LoadDMS() {
    this.currentState = LoadStates.LOADDMS;
    this.database.UpdateDMSInfo();
    setTimeout(() => {
      this.servermanagement.LoadDMS();
      setTimeout(() => {
        if (!this.DMSLoaded) {
          this.Retry();
        }
      }, 10000);
    }, 2000);

  }

  public OnDmsLoaded() {
    this.retryCounter = 0;
    this.DMSLoaded = true;
    this.GetUserInfo();
  }

  public OnDmsLoadedError() {
    this.Retry();
  }

  public GetUserInfo() {
    this.currentState = LoadStates.LOADUSER;

    if (this.connectivity.inOfflineMode) {
      this.database.getOfflineUser(this.usermanagement.getStoredUser()).then(data => {
        if (data) {
          this.usermanagement.createUserFromResponse(JSON.parse(data.data));
          if (this.usermanagement.currentUser) {
            this.UserInfoLoaded = true;
            this.LoadModules();
            this.retryCounter = 0;
          } else {
            this.Retry();
          }
        }
      });
    } else {
      this.bluectrlapi.getUserInfo().subscribe((data: any) => {
        if (data.id)  {
          this.usermanagement.createUserFromResponse(data);
          if (this.usermanagement.currentUser) {
            this.UserInfoLoaded = true;
            this.LoadModules();
            this.retryCounter = 0;
          } else {
            this.Retry();
          }
        } else {
          this.Retry();
        }
        // this.CurrentUser = this.usermanagement.currentUser;
      }, error => {
        console.log(error);
        this.Retry();
      });

      setTimeout(() => {
        if (!this.UserInfoLoaded) {
          this.Retry();
        }
      }, 10000);
    }
  }

  private Retry() {
    if (this.alreadyClosed === false) {
      if (this.retryCounter < 3) {
        this.retryCounter = this.retryCounter + 1;
        if (this.currentState === LoadStates.LOADUSER) {
          this.GetUserInfo();
        } else if (this.currentState === LoadStates.LOADMODULES) {
          this.LoadModules();
        } else if (this.currentState === LoadStates.LOADRECIPES) {
          this.LoadRecipes();
        } else if (this.currentState === LoadStates.LOADLASTRECIPE) {
          // this.LoadLastRecipe();
        } else if (this.currentState === LoadStates.LOADDMS) {
          this.LoadDMS();
        }

      } else {

        if (this.alreadyClosed === false) {
          this.alreadyClosed = true;
          const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOCONNECTION');
          const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOCONNECTION');
          const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
            {panelClass: 'panelclass', data: {header: msg, content: content}});

          dialogRef.afterClosed().subscribe(() => {
            this.usermanagement.LogOut();
            this.dialogRef.close(true);
          });
        }

      }
    }
  }

  public LoadModules() {
    this.currentState = LoadStates.LOADMODULES;

    // CUSTOMER MODULES
    this.servermanagement.LoadCustomerModules().then((data: any) => {
      if (data) {
        this.retryCounter = 0;
        this.CustomerModulesLoaded = true;
        if (this.connectivity.inOfflineMode) {
          this.currentState = LoadStates.LOADRECIPES;
          this.retryCounter = 0;
          this.RecipesLoadedFlag = true;
          this.Finished();
        } else {
          this.LoadRecipes();
        }


      } else {
        this.Retry();
      }
    });

    setTimeout(() => {
      if (!this.CustomerModulesLoaded) {
        this.Retry();
      }
    }, 10000);

  }

  public LoadRecipes() {
    this.currentState = LoadStates.LOADRECIPES;
    this.database.UpdateRecipesFromAPI(true);

    setTimeout(() => {
      if (!this.RecipesLoadedFlag) {
        this.Retry();
      }
    }, 100000);
  }

  public RecipesLoaded() {
    this.retryCounter = 0;
    this.RecipesLoadedFlag = true;
    this.Finished();
  }

  public RecipesLodedError() {
    this.Retry();
  }

  public Finished() {
    this.currentState = LoadStates.FINISHED;
    this.usermanagement.SetDataLoaded();
    this.moduleHandling.dataLoadedFinished();
    this.dialogRef.close(true);
  }

}
