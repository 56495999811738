import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-wifi-service-not-found',
  templateUrl: './wifi-service-not-found.component.html',
  styleUrls: ['./wifi-service-not-found.component.css']
})
export class WifiServiceNotFoundComponent implements OnInit {

  public requestedNetwork: string;

  constructor(
    private dialogRef: MatDialogRef<WifiServiceNotFoundComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.requestedNetwork = data;
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
