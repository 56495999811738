<div class="element" fxLayout="column" (click)="opened = !opened" [ngClass]="{'warning': Notification.type === 'Warning' && opened, 'error': Notification.type === 'Error' && opened,'info': Notification.type === 'Info' && opened}">
  <div fxLayout="row">
    <div fxLayout="column">
      <p class="componentText">{{Notification.ComponentTranslateId | i18next}} {{GetNotificationComponentNumber(Notification)}}:</p>
      <p *ngIf="Notification.type === 'Error'" class="componentText">{{'COMPONENTS.NOTIFICATIONVIEW.ERRORCODE' | i18next}}: {{GetNotificationCode(Notification)}}</p>
      <p *ngIf="Notification.type === 'Warning'" class="componentText">{{'COMPONENTS.NOTIFICATIONVIEW.WARNINGCODE' | i18next}}: {{GetNotificationCode(Notification)}}</p>
    </div>


    <p class="messageText">{{GetNotificationText(Notification)}}</p>

  </div>

</div>
