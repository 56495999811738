<section fxLayout="row">
  <div class="moduleInfo" fxLayout="row" (click)="ShowSettingsOnClick($event)">
    <div class="moduleImageDiv" (click)="SelectAction($event)">
      <img class="productImage" src="{{customerModule.Module.PathIconSymboleBlack}}">
    </div>
    <div class="moduleDetails" fxLayout="column" fxFlexAlign="start">
      <p class="moduleName" *ngIf="!customerModule.Module.Manufacturer; else elseBlock">{{customerModule.Module.Group}} {{customerModule.Module.Name}}</p>
      <ng-template #elseBlock>
        <p class="moduleName">{{customerModule.Module.Manufacturer}} {{customerModule.Module.Model}}</p>
      </ng-template>
      <p class="moduleSerial">{{customerModule.SerialNumber}}</p>
      <p class="moduleWard">{{customerModule.Ward.Name}}</p>
      <p *ngIf="HaveClusterId()" class="moduleCluster">{{customerModule.InventoryInfo.ClusterId}}</p>
      <div class="moduleState" fxLayout="row" fxFlexAlign="end">
        <img class="wifiavail" *ngIf="customerModule.ModuleSSID" src="/assets/general/receive_locally.svg">
        <img class="wifiavail" *ngIf="IsStandaloneOnline()" src="/assets/general/connected.svg">
      </div>
    </div>
  </div>
  <div class="moduleAction" *ngIf="(customerModule.ModuleGlobalState !== 'unknown' || customerModule.ModuleSSID) && !StandaloneClusterInitialized()">
    <img class="moduleActionIcon" *ngIf="customerModule.ModuleGlobalState === 'unknown' && customerModule.ModuleSSID" (click)="StartModuleSync()" src="/assets/general/upload_button_small.svg">
    <img class="moduleActionIcon" *ngIf="customerModule.ModuleGlobalState === 'cluster'" (click)="CheckLoadCluster()" src="/assets/general/button_select_live_mode.svg">
    <img class="moduleActionIcon" *ngIf="customerModule.ModuleGlobalState === 'service' || customerModule.ModuleGlobalState === 'clusterservice'"  (click)="CheckLoadCluster()" src="/assets/general/button_select_service_mode.svg">
  </div>
  <div class="moduleActionService" *ngIf="ShowServiceDetailView()">
    <img class="moduleActionIcon" (click)="ShowSettings.emit(customerModule)"  src="/assets/general/button_select_service_mode.svg">
  </div>

  <div class="moduleActionEmergency" *ngIf="ShowEmergencyStopRequired()">
    <img class="moduleActionIcon" src="/assets/general/button_press_emergency_stop.svg">
  </div>
  <div class="moduleActionEmergency" *ngIf="ShowEmergencyNotOk()">
    <img class="moduleActionIcon" src="/assets/general/button_press_emergency_stop_active.svg">
  </div>
</section>
