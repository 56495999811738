<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch" fxLayoutGap="10px">
    <div fxLayout="column" fxFlexAlign="center">
      <h2  class="uppercaseText">{{'DIALOGS.CONVEYORSELECTION.TITLE' | i18next }}</h2>
    </div>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()">
        <img class="conveyorSelectionClose" src="/assets/general/close.svg">
      </button>

    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="stretch" fxLayoutGap="10px">
    <div *ngFor="let tp of conveyorTypes">
      <div class="conveyor long" fxLayout="column" (click)="selected(tp)">
        <img class="conveyorImage" src="{{getPath(tp)}}">
        <p class="conveyorName">{{tp.Name}}</p>
      </div>
    </div>
  </div>

</section>
