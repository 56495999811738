import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerModule} from '../../../classes/customer-module';
import {NetworkDevice} from '../../../classes/messaging/network-device';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';

@Component({
  selector: 'app-inventory-row',
  templateUrl: './inventory-row.component.html',
  styleUrls: ['./inventory-row.component.css']
})
export class InventoryRowComponent implements OnInit {

  public _modules: CustomerModule[];
  public currentIndex = 0;
  OwnVisible = true;

  @Output() CustomerModuleSelected = new EventEmitter<CustomerModule>();
  @Output() ShowModuleDetailView = new EventEmitter<CustomerModule>();
  @Input() Group: string;
  @Input() set Modules(value: CustomerModule[]) {
    this._modules = value;
    // if (this._modules != null && this._modules !== undefined) {
    //
    //   if (this.messageHandling.Devices) {
    //     for (const d of this.messageHandling.Devices) {
    //       const md = this._modules.find(ex => ex.SerialNumber === d.serialnumber);
    //       if (md) {
    //         md.Module.NetworkInfo = d;
    //       }
    //     }
    //   }
    // }
  }

  constructor(public messageHandling: MessageHandlingService) {
    this.messageHandling.ModuleOnlineStateChanged.subscribe(this.NetworkInfoChanged.bind(this));
  }

  ngOnInit(): void {
  }

  public GetCurrentArrayLength(): number {
    if (this._modules !== null && this._modules !== undefined) {
      return this._modules.length;
    } else {
      return 0;
    }
  }

  public CustomerModuleClicked(customerModule: CustomerModule) {
      this.CustomerModuleSelected.emit(customerModule);
  }

  public emitShowDetailView(customerModule: CustomerModule) {
    this.ShowModuleDetailView.emit(customerModule);
  }

  public NetworkInfoChanged(msg: any) {

    const mdl = this._modules.find(ex => ex.SerialNumber === msg.ctxId);

    if (mdl) {
      if (msg.online) {
        const networkinfo = new NetworkDevice(msg.ctxId,
          msg.ipaddress,
          msg.revision,
          msg.statemodule,
          msg.switchstate,
          msg.belts,
          msg.emergencystoprequired,
          msg.emergencystopok,
          msg.levellingrequired,
          msg.nodeversion,
          msg.plcversion,
          msg.specversion);

        // mdl.Module.AddNetworkInfo((networkinfo));

          // CORRECT
        mdl.Module.AddNetworkInfo(networkinfo as NetworkDevice);

        mdl.Module.Connected = true;
        mdl.InternalBeltStates = networkinfo.belts;

        mdl.Module.EmergencyStopRequired = networkinfo.emergencystoprequired;
        mdl.Module.LevelingRequired = networkinfo.levellingrequired;
          if (networkinfo.switchstate === 'service') {
            mdl.Module.EnableServiceMode();
          } else {
            mdl.Module.DisableServiceMode();
          }

          if (networkinfo.additionalModuleInformations) {
            for (const addi of networkinfo.additionalModuleInformations) {
              // FIND VARIABLE
              if (addi.name) {
                const name = addi.name as string;
                const split = name.split('.');
                if (split.length > 1) {
                  const comp = split[0];
                  let vari = split[1];

                  for (let i = 2; i < split.length; i++) {
                    vari = vari + '.' + split[i];
                  }

                  const component = mdl.Module.Components.find(ex => ex.PlcKey === comp);

                  if (component) {
                    // FIND VARIABLE
                    const configVar = component.Configs.find(ex => ex.UAPath === vari);

                    if (configVar) {
                      configVar.SetAdditionalInfo(addi.values);
                    }

                    const monitorVar = component.Monitorings.find(ex => ex.UAPath === vari);

                    if (monitorVar) {
                      monitorVar.SetAdditionalInfo(addi.values);
                    }

                    const maintVar = component.Maintenance.find(ex => ex.UAPath === vari);

                    if (maintVar) {
                      maintVar.SetAdditionalInfo(addi.values);
                    }

                  }
                }
              }
            }
          }
      } else {
        mdl.Module.AddNetworkInfo(null);
        mdl.ActiveErrorNotifications = [];
        mdl.ActiveWarningNotifications = [];
        mdl.Module.setOffline();
        mdl.Module.DisableServiceMode();
      }
    }
  }

}
