import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatCalendar} from '@angular/material/datepicker';
import {Moment} from 'moment';
import {User} from '../../../classes/user';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {UserManagementService} from '../../../services/user-management.service';
import {DatabaseService} from '../../../services/database.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Project} from '../../../classes/project';
import {TransferRecipeDialogComponent} from '../../dialogs/transfer-recipe-dialog/transfer-recipe-dialog.component';
import {ConnectivityService} from '../../../services/connectivity.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {QuestionBoxComponent} from '../../dialogs/question-box/question-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-load-project',
  templateUrl: './load-project.component.html',
  styleUrls: ['./load-project.component.css']
})
export class LoadProjectComponent implements OnInit {

  form: FormGroup;
  name: string;
  description: string;
  public users: User[] = [];
  private currentUser: User;
  private recipies: any[];
  public filteredRecipies: any[];
  public selectedRecipie = null;
  private _searchText: string;
  public _selectedUser: User;
  private _showLoad = false;
  private ProjectToLoad: Project;

  @ViewChild('calendar', {static: true}) calendar: MatCalendar<Moment>;
  @ViewChild('recipeContainer', { read: ElementRef, static: false}) public widgetsContent: ElementRef<any>;

  @Output() CloseLoadProject = new EventEmitter<Project>();

  @Input() set ShowLoad(value: boolean) {
    this._showLoad = value;
    if (this.ShowLoad === true) {
      this.currentUser = this.userMngmt.currentUser;
      this.LoadRecipiesFromDb();
    }
  }

  get ShowLoad(): boolean {
    return this._showLoad;
  }

  constructor(public server: ServerCommunicationService,
              public userMngmt: UserManagementService,
              public database: DatabaseService,
              public moduleHandling: ModuleHandlingService,
              public connectivity: ConnectivityService,
              private fb: FormBuilder,
              public dialog: MatDialog,
              public translate: BluectrlTranslateService,
              public router: Router,
              ) {

    this.form = fb.group({
      Search: [this.name, Validators.required],
    });

    this.moduleHandling.OnProjectLoaded.subscribe(this.OnProjectLoaded.bind(this));

    this.currentUser = this.userMngmt.currentUser;


  }

  LoadRecipiesFromDb() {
    this.database.recipes.toArray().then(rec => {
      this.recipies = rec.sort((a,b) => a.name.localeCompare(b.name));
      this.recipies = this.recipies.filter(ex => (ex.customer === this.currentUser.CustomerId || ex.customer === '967aa411-198f-4bbd-b59d-6b91bd54408a') && ex.name !== 'temp');
      console.log(this.recipies);
      this.filteredRecipies = this.recipies;
    });
  }

  CloseDialog() {
    // this.dialogRef.close();
    this.CloseLoadProject.emit(null);
  }

  ngOnInit() {
    this.database.UpdateRecipesFromAPI(true);
    this.LoadRecipiesFromDb();
  }

  public ScrollDown() {
    this.widgetsContent.nativeElement.scrollTop =  this.widgetsContent.nativeElement.scrollTop + 50;
  }

  public ScrollUp() {
    this.widgetsContent.nativeElement.scrollTop = this.widgetsContent.nativeElement.scrollTop >= 0 ?
      this.widgetsContent.nativeElement.scrollTop - 50 : 0;
  }

  public DownScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      const off = this.widgetsContent.nativeElement.clientHeight + this.widgetsContent.nativeElement.scrollTop;
      if (off < this.widgetsContent.nativeElement.scrollHeight) {
        return true;
      }
    }
    return false;
  }

  public UpScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null ) {
      if (this.widgetsContent.nativeElement.scrollTop > 0) {
        return true;
      }
    }
    return false;
  }

  public UpdateSearchFilter() {

    this.filteredRecipies = this.recipies;

    if (this._selectedUser != null && this._selectedUser !== undefined) {
      this.filteredRecipies = this.filteredRecipies.filter(ex => ex.author === this._selectedUser.Id);
    }

    if (this._searchText !== null && this._searchText !== undefined && this._searchText.length > 2) {
      this.filteredRecipies = this.filteredRecipies.filter(ex =>
        ex.name.toUpperCase().includes(this._searchText.toUpperCase()) ||
        ex.description.toUpperCase().includes(this._searchText.toUpperCase()));
    }

    // if (this._selectedDate !== null && this._selectedDate !== undefined) {
    //   const dPipe = new DatePipe('en-US');
    //
    //   this.filteredRecipies = this.filteredRecipies.filter(ex => dPipe.transform(ex.created, 'dd-MM-yyyy') ===
    //     dPipe.transform(this._selectedDate, 'dd-MM-yyyy'));
    // }
  }

  SelectFile(rec: any) {
    this.ProjectToLoad = null;
    this.selectedRecipie = rec;
  }

  GetUserById(userId: string): User {
    return this.users.find(ex => ex.Id === userId);
  }

  RemoveUserFilter() {
    this._selectedUser = null;
    this.UpdateSearchFilter();
  }

  LoadRecipie() {
    let project = new Project(this.selectedRecipie.name);
    project.version = this.selectedRecipie.version;
    project.created = this.selectedRecipie.created;
    project.author = this.selectedRecipie.author;
    project.id = this.selectedRecipie.id;
    project.valid = this.selectedRecipie.valid;
    project.description = this.selectedRecipie.description;
    if (this.selectedRecipie.tempSaved) {
      project.tempSaved = this.selectedRecipie.tempSaved;
    }


    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      project = this.moduleHandling.LoadRecipieForValidation(project, this.selectedRecipie.recipe, this.userMngmt.currentUser.CustomerId, this.server);

      if (this.moduleHandling.ValidateProject(project).length > 0) {
        const msg = this.translate.GetTranslation('MESSAGEBOX.CONTENT.INVALIDRECIPEINCOOKIE');
        // const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.RECIPENOTVALID');
        const dialogRef = this.dialog.open(QuestionBoxComponent,
          {panelClass: 'panelclass', data: msg});
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.moduleHandling.SetProjectToCookie(project);
            this.router.navigate(['/design']);
          } else {
            this.ProjectToLoad =null;
            return;
          }

        });
      } else {
        this.ProjectToLoad = project;
        this.moduleHandling.LoadRecipie(project, this.selectedRecipie.recipe, this.userMngmt.currentUser.CustomerId, this.server, true);
      }

    } else {
      this.ProjectToLoad = project;
      this.moduleHandling.LoadRecipie(project, this.selectedRecipie.recipe, this.userMngmt.currentUser.CustomerId, this.server, true);
    }



    // DELETE TEMPS OF CUSTOMER
    this.database.recipes.toArray().then(rec => {
      const toDel = rec.filter(ex => ex.customer === this.currentUser.CustomerId && ex.name === 'temp');
      for (const d of toDel) {
        this.database.DeleteRecipie(d.id);
      }
    });



    this.ProjectToLoad = project;




    // return project

  }

  CopyRecipe() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialog';

    dialogConfig.data = this.selectedRecipie.id;
    this.dialog.open(TransferRecipeDialogComponent,
      dialogConfig);


  }

  DeleteRecipie() {
    this.database.DeleteRecipie(this.selectedRecipie.id);
    this.LoadRecipiesFromDb();
    this.selectedRecipie = null;
  }

  public IsRecipeOnline(recipe: any): boolean {
    if (recipe) {
      if (recipe.id) {
        if (this.database.globalRecipes.find(ex => ex.id === recipe.id)) {
          return true;
        }
      }
    }

    return false;
  }

  public OnProjectLoaded() {
    if (this.ProjectToLoad) {
      this.CloseLoadProject.emit(this.ProjectToLoad);
    }

  }



}
