import {Component, Inject, OnInit} from '@angular/core';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {UserManagementService} from '../../../services/user-management.service';
import {DatabaseService} from '../../../services/database.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../../../classes/project';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';

@Component({
  selector: 'app-save-project-dialog',
  templateUrl: './save-project-dialog.component.html',
  styleUrls: ['./save-project-dialog.component.css']
})
export class SaveProjectDialogComponent implements OnInit {


  public project: Project;
  form: FormGroup;
  OwnVisible = false;
  name: string;
  description: string;
  public sqno = 0;
  public errorText = '';

  public namePattern =  '^[a-zA-Z0-9_+\\ äöüÄÖÜßẞ_+\\(\\)\\*@\\/;,\\.=-]+$'; //'[a-zA-Z0-9_+\\(\\)\\*@\\/;,\\.=-]';

  constructor(public server: ServerCommunicationService,
              public usermanagement: UserManagementService,
              public database: DatabaseService,
              public moduleHandling: ModuleHandlingService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<SaveProjectDialogComponent>,
              public translate: BluectrlTranslateService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.project = data;

    if (this.project.name === 'temp') {
      this.name = this.translate.GetTranslation('DIALOGS.SAVERECIPE.SAVERECIPENAME');
    } else {
      this.name = this.project.name;
    }


    this.description = this.project.description;

    this.form = fb.group({
      ProjectName: [this.name, Validators.required],
      Description: [this.description, Validators.required]

    });

    this.form.value.ProjectName = this.project.name;
    this.form.value.Description = this.project.description;

    this.moduleHandling.ProjectSavingFinished.subscribe(this.SaveProjectFinished.bind(this));

  }

  ngOnInit() {
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  public SaveProjectFinished(value: boolean) {
    if (value) {
      this.dialogRef.close(this.moduleHandling.ActiveProject);
    }
  }

  public SaveProject() {
    // const data = this.moduleHandling.CreateRecipeDatabase(this.project);

    const name = this.form.value.ProjectName;
    const description = this.form.value.Description;

    // CHECK NAME PATTERN
    let match = name.trim().match(this.namePattern);

    if (!match) {
      this.errorText = this.translate.GetTranslation('DIALOGS.SAVERECIPE.INVALIDSYMBOLS'); 'Name enthält unzulässige Zeichen';
      return;
    }

    if (name.trim().toUpperCase().startsWith('TEMP') || name.trim().toUpperCase().startsWith('SERVICE')) {
      this.errorText = this.translate.GetTranslation('DIALOGS.SAVERECIPE.INVALIDNAME');
      return;
    }


    this.moduleHandling.saveCurrentProject(name, description);

    // this.project.name = name;
    // this.project.description = description;
    //
    // // Delete Temp if exists
    // if (this.project.tempSaved === true && this.project.id) {
    //   // DELETE IT
    //   this.database.DeleteRecipie(this.project.id);
    //   // this.project.id = null;
    // }
    //
    // // CLEAR ALL TEMP
    // this.database.recipes.toArray().then(recs => {
    //   const allrcs = recs.filter(ex => ex.name === '' || ex.name === null || ex.name === undefined || ex.name.indexOf('temp') >= 0);
    //   for (const r of allrcs) {
    //     this.database.DeleteRecipie(r.id);
    //   }
    // });
    // const savevalue = {
    //     id: this.project.id,
    //     name: name,
    //     description: description,
    //     customer: this.usermanagement.currentUser.CustomerId,
    //     author: (this.project.author !== undefined && this.project.author !== null ?
    //       this.project.author :
    //       this.usermanagement.currentUser.Id),
    //     created: this.project.created,
    //     public: this.project.public,
    //     modified: new Date(),
    //     valid: this.moduleHandling.ValidateProject().length <= 0,
    //   recipe: JSON.stringify(data)
    // };
    //
    // const returnOfSave = this.database.AddNewRecipe(savevalue);
    //
    // returnOfSave.then((result: any) => {
    //
    //   if (result === false) {
    //     // ERROR ON SAVE
    //   } else {
    //       this.project.id = result as string;
    //       this.project.name = name;
    //       this.project.description = description;
    //       this.moduleHandling.SetProjectToCookie(this.project);
    //
    //     this.dialogRef.close(this.project);
    //   }
    // });
  }
}
