<section fxLayout="row">
  <div fxLayout="column" class="tasktab">
    <h1>{{'COMPONENTS.SETUPTASKLIST.TITLE' | i18next}} </h1>
    <div fxLayout="column"  fxFlexAlign="center" class="downscrollarrow flipArrow">
      <button mat-button class="arrowbtn" (click)="ScrollUp()" *ngIf="UpScrolling()">
        <img src="/assets/general/arrow_scroll.svg">
      </button>
    </div>
    <div class="totaldiv" #taskContainer *ngIf="ActiveTasks && ActiveTasks.length > 0">
      <mat-list class="listfortask" *ngFor="let tsk of ActiveTasks" #taskList>
        <div fxLayout="row" class="taskelement">
          <input class="checkbx" type="checkbox" id="scales" name="scales" (click)="EnableTask(tsk)" [checked]="tsk.Done">
          <p>{{GetTaskText(tsk)}}</p>
        </div>
      </mat-list>
    </div>
    <div fxLayout="column"  fxFlexAlign="center" class="downscrollarrow setupListArrow">
      <button mat-button class="arrowbtn" (click)="ScrollDown()" *ngIf="DownScrolling()">
        <img src="/assets/general/arrow_scroll.svg">
      </button>
    </div>
       <!-- <button *ngIf="!CheckIfDone()" (click)="SetAllDone()" class="doneButton topButtonDone">{{'COMPONENTS.SETUPTASKLIST.CONFIRMALL' | i18next }}</button>
       -->
    <ng-template #elseBlock >
    </ng-template>
  </div>
</section>
