<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 class="uppercaseText" fxFlexAlign="center" mat-dialog-title>{{'DIALOGS.NEWMODULE.TITLE' | i18next }}</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()">
        <img class="invertedWhite" src="/assets/general/close.svg">
      </button>
    </div>
  </div>

  <div class="inputs" fxLayout="column">
    <mat-dialog-content fxLayout="column">
      <div fxLayout="row" class="selectorDiv">
        <p class="label">{{'DIALOGS.NEWMODULE.ORGANIZATION' | i18next }}</p>
        <mat-select class="selectElement"
                    [(ngModel)]="selectedOrganisation">
          <mat-select-trigger *ngIf="selectedOrganisation" class="selecttrigger">
            {{selectedOrganisation.name}}
          </mat-select-trigger>
          <mat-option *ngFor="let org of organisations"
                      [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </div>

      <div fxLayout="row" class="selectorDiv">
        <p class="label">{{'DIALOGS.NEWMODULE.GROUP' | i18next }}</p>
        <mat-select class="selectElement"
                    (selectionChange)="groupSelectChange($event)"
                    [(ngModel)]="selectedGroup">
          <mat-select-trigger *ngIf="selectedGroup" class="selecttrigger">
            {{selectedGroup.name}}
          </mat-select-trigger>
          <mat-option *ngFor="let group of groups"
                      [value]="group">{{group.name}}</mat-option>
        </mat-select>
      </div>
      <div fxLayout="row" class="selectorDiv">
        <p class="label">{{'DIALOGS.NEWMODULE.NAME' | i18next }}</p>
        <mat-select class="selectElement"
                    [disabled]="!selectableNames"
                    (selectionChange)="namesSelectChange($event)"
                    [(ngModel)]="selectedName">
          <mat-select-trigger *ngIf="selectedName" class="selecttrigger">
            {{selectedName.name}}
          </mat-select-trigger>
          <mat-option *ngFor="let name of selectableNames"
                      [value]="name">{{name.name}}</mat-option>
        </mat-select>
      </div>
      <div fxLayout="row" class="selectorDiv">
        <p class="label">{{'DIALOGS.NEWMODULE.VERSION' | i18next }}</p>
        <mat-select class="selectElement"
                    [disabled]="!selectableVersions"
                    (selectionChange)="versionSelectChange($event)"
                    [(ngModel)]="selectedVersion">
          <mat-select-trigger *ngIf="selectedVersion" class="selecttrigger">
            {{selectedVersion.id}}
          </mat-select-trigger>
          <mat-option *ngFor="let vers of selectableVersions"
                      [value]="vers">{{vers.id}}</mat-option>
        </mat-select>
      </div>



      <mat-form-field class="inputblue">
        <input required matInput placeholder="{{'DIALOGS.NEWMODULE.SERIAL' | i18next }}" [(ngModel)]="serial">
      </mat-form-field>


    </mat-dialog-content>

    <div *ngIf="selectedVersion">
      <p label="errortext"  *ngIf="errorText">{{errorText}}</p>
    </div>

  </div>

  <div class="savediv" fxLayout="row" fxFlexAlign="center">
    <button mat-button (click)="SaveModule()" *ngIf="savePossible()">
      <img class="invertedWhite" src="/assets/general/save.svg">
    </button>
  </div>

</section>
