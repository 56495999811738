<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 class="uppercaseText" fxFlexAlign="center" mat-dialog-title>{{'DIALOGS.SAVERECIPE.TITLE' | i18next }}</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()">
         <img [ngClass]="{'invertedWhite': moduleHandling.CurrentViewMode !== 1, 'invertedBlack': moduleHandling.CurrentViewMode === 1}" src="/assets/general/close.svg">
      </button>
    </div>
  </div>

  <div class="inputs" fxLayout="column">
    <mat-dialog-content [formGroup]="form" fxLayout="column">
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 0" class="inputblue">
        <input matInput placeholder="{{'DIALOGS.SAVERECIPE.PROJECTNAME' | i18next }}" formControlName="ProjectName">
      </mat-form-field>
      <div class="errorinfo" *ngIf="errorText">
        {{errorText}}
      </div>
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 0" class="inputblue">
        <textarea matInput placeholder="{{'DIALOGS.SAVERECIPE.DESCRIPTION' | i18next }}" formControlName="Description"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 1" class="inputgray">
        <input matInput placeholder="{{'DIALOGS.SAVERECIPE.PROJECTNAME' | i18next }}" formControlName="ProjectName">
      </mat-form-field>
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 1" class="inputgray">
        <textarea matInput placeholder="{{'DIALOGS.SAVERECIPE.DESCRIPTION' | i18next }}" formControlName="Description"></textarea>
      </mat-form-field>
    </mat-dialog-content>
  </div>

  <div fxLayout="column" class="infos" *ngIf="moduleHandling.ValidateProject().length > 0">
    <div fxLayout="row" class="moduleheader">
      <p>{{'DIALOGS.SAVERECIPE.VALIDATION' | i18next }} ( {{moduleHandling.ValidateProject().length}} ) </p>
      <svg class="updownicon" *ngIf="!OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 32 32" (click)="OwnVisible = true">
        <g>
          <rect height="32" width="32" fill="none" />
          <path id="path1" transform="rotate(0,16,16) translate(0,6.1274995803833) scale(1,1)  " d="M0,0L32,0 16,19.745z" />
        </g>
      </svg>
      <svg class="updownicon" *ngIf="OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 32 32" (click)="OwnVisible = false">
        <g>
          <rect height="32" width="32" fill="none" />
          <path id="path1" transform="rotate(180,16,16) translate(0,6.1274995803833) scale(1,1)  " d="M0,0L32,0 16,19.745z" />
        </g>
      </svg>
    </div>
    <div class="tablediv" *ngIf="OwnVisible">
      <table class="infotable" fxLayout="column" *ngFor="let inf of moduleHandling.ValidateProject()">
        <tr  class="elementRow" fxLayout="row" fxFlexAlign="stretch">
          <td style="width: 35%" class="elementText"><p class="contentp">{{inf.module}}</p></td>
          <td style="width: 65%" class="elementText"><p class="contentp">{{inf.text}}</p></td>
        </tr>
      </table>
    </div>
  </div>

  <div class="savediv" fxLayout="row" fxFlexAlign="center">
    <button mat-button (click)="SaveProject()">
       <img [ngClass]="{'invertedWhite': moduleHandling.CurrentViewMode !== 1, 'invertedBlack': moduleHandling.CurrentViewMode === 1}" src="/assets/general/save.svg">
    </button>
  </div>
</section>
