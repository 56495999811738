import {ComponentFeature} from './component-feature';
import {ComponentVariable} from './component-variable';
import {ModuleConnections} from './enums/module-connections.enum';
import {NotificationEntry} from './notificationEntry';
import {EventEmitter} from '@angular/core';

export class ModuleComponent {
  public PlcKey: string;
  public Name: string;
  public Type: string;
  public TranslationId: string;
  public Text: string;
  public Virtual = false;
  public Features: ComponentFeature[];
  public Configs: ComponentVariable[];
  public Maintenance: ComponentVariable[];
  public Monitorings: ComponentVariable[];
  public Connections: ModuleConnections[];
  // public Errors: NotificationEntry[];
  // public Warnings: NotificationEntry[];
  public Top: number;
  public Left: number;
  public Width: number;
  public Height: number;
  public Path: string;

  private _ServiceSelected = false;
  public ServiceConfigurationChanged = false;

  public get ServiceSelected(): boolean
  {
    return this._ServiceSelected;
  }

  public set ServiceSelected(value: boolean) {
    this._ServiceSelected = value;

    // if (value === false) {
    //   this.ServiceConfigurationChanged = false;
    // } else {
    //   if (this.Maintenance && this.Maintenance.length > 0) {
    //     if (this.Maintenance.find(ex => ex.Name.toUpperCase() === 'SERVICEACTIVE')) {
    //       this.ServiceConfigurationChanged = true;
    //     }
    //   }
    // }
  }

  public SvgPath: string;
  public ComponentVariableChanged = new EventEmitter<any>();
  public ComponentMaintenanceVariableChanged = new EventEmitter<any>();

  constructor(key: string, name: string, type: string, moduletype: string) {
    this.PlcKey = key;
    this.Name = name;
    this.Type = type;
    this.Text = name;
    this.TranslationId = 'MODULES.COMPONENTS.' + type + '.NAME';
    this.Features = [];
    this.Configs = [];
    this.Monitorings = [];
    this.Maintenance = [];
    this.Connections = [];
    // this.Errors = [];
    // this.Warnings = [];
    this.SvgPath = 'assets/ModuleComponents/' + moduletype + '_' + this.PlcKey + '.svg';

  }

  public AddFeature(feature: ComponentFeature) {
    this.Features.push(feature);
  }

  public GetName(): string {
    return this.Text;
  }

  public GetTranslationId(): string {
    return this.TranslationId;
  }

  public Copy(): ModuleComponent {
    const mc = new ModuleComponent(this.PlcKey, this.Name, this.Type, null);
    mc.TranslationId = this.TranslationId;
    mc.Path = this.Path;
    mc.Left = this.Left;
    mc.Top = this.Top;
    mc.Height = this.Height;
    mc.Width = this.Width;
    mc.Text = this.Text;
    mc.Virtual = this.Virtual;

    // for (const w of this.Warnings) {
    //   mc.Warnings.push(w.Copy());
    // }
    // for (const w of this.Errors) {
    //   mc.Errors.push(w.Copy());
    // }
    for (const w of this.Configs) {
      mc.AddConfigParameter(w.Copy());
    }
    for (const w of this.Monitorings) {
      mc.Monitorings.push(w.Copy());
    }

    for (const w of this.Maintenance) {
      mc.AddMaintenanceParameter(w.Copy());
    }

    for (const w of this.Features) {
      mc.Features.push(w.Copy());
    }
    mc.Connections = this.Connections;
    mc.SvgPath = this.SvgPath;
    return mc;
  }

  public GetValue(name: string): ComponentVariable {
    if (this.Configs.find(ex => ex.Name === name)) {
      return this.Configs.find(ex => ex.Name === name);
    } else if (this.Monitorings.find(ex => ex.Name === name)) {
      return this.Monitorings.find(ex => ex.Name === name);
    } else {
      return undefined;
    }
  }

  public SetValue(name: string, value): boolean {
    if (this.Configs.find(ex => ex.Name === name)) {
      return this.Configs.find(ex => ex.Name === name).SetValue(value);
    } else {
      return false;
    }
  }

  public AddConfigParameter(param: ComponentVariable) {
    this.Configs.push(param);
    param.VariableValueChanged.subscribe(this.VariableValueChanged.bind(this));
  }

  public AddMaintenanceParameter(param: ComponentVariable) {
    this.Maintenance.push(param);
    param.VariableValueChanged.subscribe(this.MaintenanceVariableValueChanged.bind(this));
  }

  public VariableValueChanged(data: any) {
    const newData = {
      component: this.PlcKey,
      variable: data.variable,
      value: data.value
    };
    this.ComponentVariableChanged.emit(newData);
  }

  public MaintenanceVariableValueChanged(data: any) {
    const newData = {
      component: this.PlcKey,
      variable: data.variable,
      value: data.value
    };
    this.ComponentMaintenanceVariableChanged.emit(newData);
  }

  public getComponentNumber(): string {
    if (this.PlcKey) {
      const nmbr = this.PlcKey.substr(this.PlcKey.length - 2, 2);
      const nbr = Number(nmbr);
      if (nbr) {
        return nbr.toString();
      }
    }
    return '';
  }
}
