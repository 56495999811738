import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {UserManagementService} from '../../../services/user-management.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {MatDialog} from '@angular/material/dialog';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {i18n} from 'i18next';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  public test = '';

  // https://github.com/ngx-translate/core
  constructor(private usermngt: UserManagementService,
              private server: ServerCommunicationService,
              private dialog: MatDialog,
              private moduleHandling: ModuleHandlingService,
              @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService
              ) {
    this.transChanged();
  }

  public transChanged() {
  }

  ngOnInit() {
    this.moduleHandling.SetViewMode(ViewCode.home);
  }

  ngAfterViewInit(): void {


  }

}
