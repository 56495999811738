import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-project-save-warning',
  templateUrl: './project-save-warning.component.html',
  styleUrls: ['./project-save-warning.component.css']
})
export class ProjectSaveWarningComponent implements OnInit {

  constructor(public DialogRef: MatDialogRef<ProjectSaveWarningComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  public Change() {
    this.DialogRef.close(true);
  }

  public Cancel() {
    this.DialogRef.close(false);
  }

}
