import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import {Project} from '../../../classes/project';
import {ModuleConnections} from '../../../classes/enums/module-connections.enum';
import {EndPointTypes} from '../../../classes/enums/end-point-types.enum';
import {ModulePlan} from '../../../classes/module-plan';
import {CustomerModule} from '../../../classes/customer-module';
import {ModuleDefinition} from '../../../classes/module-definition';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {ComponentVariable} from '../../../classes/component-variable';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.css']
})
export class ShareDialogComponent implements OnInit {

  form: FormGroup;
  link: string;
  project: Project;
  public shareOn = false;

  constructor( private dialogRef: MatDialogRef<ShareDialogComponent>,
               private fb: FormBuilder,
               @Inject(MAT_DIALOG_DATA) data,
               private moduleHandling: ModuleHandlingService,
               public datePipe: DatePipe,
               public translate: BluectrlTranslateService) {

    this.project = data;

    if (this.project) {
      const br = JSON.stringify(this.moduleHandling.CreateRecipeDatabase(this.project));
      const comp = btoa(br);
      this.link = window.location.href + '/' + comp;

    }

    this.form = fb.group({
      link: [this.link, Validators.required],
    });



  }

  ngOnInit(): void {

  }

  public ShowShare() {
    this.shareOn = true;
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  CopyToClipboard() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.link));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  // https://codezup.com/angular-9-10-export-table-data-to-pdf-using-jspdf/
  // https://artskydj.github.io/jsPDF/docs/jsPDF.html#line

  CreatePDF() {

    this.shareOn = false;
    if (this.project) {
      const doc = new jsPDF.jsPDF();
      doc.setProperties({title: 'BLUELINE Recipe ' + this.project.name});


      doc.setFontSize(18);
      doc.text('Project: ', 11, 16);
      doc.text('Date: ', 11, 24);

      doc.setFont('helvetica', 'bold');
      doc.text(this.project.name, 40, 16);
      doc.text(this.datePipe.transform(new Date(), 'dd.MM.yyyy'), 40, 24);

      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text('Page ' + (doc.internal.pages.length - 1), doc.internal.pageSize.getWidth() -
        doc.getTextWidth('Site ' + doc.internal.pages.length) - 5, 24);

      doc.line(11, 32, 200, 32);



      let currenty = 42;

      doc.setFontSize(20);
      doc.setFont('helvetica', 'bold');
      doc.text('MODULES', 11, currenty);
      currenty += 13;

      const xModules = 15;
      const xDetailHeader = 20;
      const xDetail = 25;


      for (const mdls of this.project.Modules) {
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');

        let serial = '';

        if (mdls.customerModule.SerialNumber) {
          serial = ' (' + mdls.customerModule.SerialNumber + ')';
        }

        doc.text(mdls.modul.Group + ' ' + mdls.modul.Name + ' ' + mdls.modul.Version + serial, xModules, currenty);
        currenty += 7;

        doc.setFont('helvetica', 'normal');
        doc.text('Outputs', xDetailHeader, currenty);
        currenty += 7;

        const beltsInp = [];

        if (mdls.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_3) ||
          mdls.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_3)) {
          beltsInp.push({
            connection: ModuleConnections.left_3,
            name: 'left 1'
          });
          beltsInp.push({
            connection: ModuleConnections.left_1,
            name: 'left 2'
          });
          beltsInp.push({
            connection: ModuleConnections.left_2,
            name: 'left 3'
          });

          beltsInp.push({
            connection: ModuleConnections.right_3,
            name: 'right 1'
          });
          beltsInp.push({
            connection: ModuleConnections.right_1,
            name: 'right 2'
          });
          beltsInp.push({
            connection: ModuleConnections.right_2,
            name: 'right 3'
          });
        } else if (mdls.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_2) ||
          mdls.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_2)) {

          beltsInp.push({
            connection: ModuleConnections.left_1,
            name: 'left 1'
          });
          beltsInp.push({
            connection: ModuleConnections.left_2,
            name: 'left 2'
          });
          beltsInp.push({
            connection: ModuleConnections.right_1,
            name: 'right 1'
          });
          beltsInp.push({
            connection: ModuleConnections.right_2,
            name: 'right 2'
          });
        } else if (mdls.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left) ||
          mdls.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right)) {

          beltsInp.push({
            connection: ModuleConnections.left,
            name: 'left'
          });

          beltsInp.push({
            connection: ModuleConnections.right,
            name: 'right'
          });
        }
        if (mdls.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.center)) {
          beltsInp.push({
            connection: ModuleConnections.center,
            name: 'center'
          });
        }

        doc.setFontSize(14);


        for (const con of mdls.modul.ConveyorBelts) {
          const name = beltsInp.find(ex => ex.connection === con.ConnectionPoint);
          if (name) {
            doc.setFont('helvetica', 'normal');

            if (mdls.connections.find(ex => ex.moduleConnection === con.ConnectionPoint)) {
              const convCon = mdls.connections.find(ex => ex.moduleConnection === con.ConnectionPoint);

              if (convCon.conveyor) {
                if (convCon.conveyor.EndPointType === EndPointTypes.module) {
                  doc.text('\u2022' + ' ' + name.name + ':', xDetail, currenty);
                  doc.setFont('helvetica', 'bold');

                  const md = convCon.conveyor.EndPoint as ModulePlan;

                  if (md.modul) {
                    doc.text(md.modul.Group + ' ' + md.modul.Name + ' ' + md.modul.Version, xDetail + 35, currenty);
                  } else {
                    const cmd = convCon.conveyor.EndPoint as CustomerModule;

                    if (cmd.Module) {
                      doc.text(cmd.Module.Group + ' ' + cmd.Module.Name + ' ' + cmd.Module.Version, xDetail + 35, currenty);
                    } else {
                      const mmd = convCon.conveyor.EndPoint as ModuleDefinition;

                      if (mmd.Version) {
                        doc.text(mmd.Group + ' ' + mmd.Name + ' ' + mmd.Version, xDetail + 35, currenty);
                      } else {
                        doc.text('unknown', xDetail + 35, currenty);
                      }
                    }
                  }
                } else {
                  doc.text('\u2022' + ' ' + name.name + ':', xDetail, currenty);
                  doc.setFont('helvetica', 'bold');
                  doc.text('sink', xDetail + 35, currenty);
                }
              } else {
                doc.text('\u2022' + ' ' + name.name + ':', xDetail, currenty);
                doc.setFont('helvetica', 'bold');
                doc.text('not connected', xDetail + 35, currenty);
              }

            } else {
              doc.text('\u2022' + ' ' + name.name + ':', xDetail, currenty);
              doc.setFont('helvetica', 'bold');
              doc.text('not connected', xDetail + 35, currenty);
            }

            currenty = this.updateCurrentY(doc, currenty, 6);

          }
        }

        currenty = this.updateCurrentY(doc, currenty, 3);
        doc.setFontSize(16);
        doc.setFont('helvetica', 'normal');
        doc.text('Configuration', xDetailHeader, currenty);
        currenty = this.updateCurrentY(doc, currenty, 7);

        for (const component of mdls.modul.Components.filter(ex =>
          ex.Configs.filter(et => et.Virtual === false && et.ConstallationDependency === false).length > 0)) {
          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text(this.translate.GetTranslation(component.TranslationId) + ' ' + component.PlcKey, xDetail, currenty);
          currenty = this.updateCurrentY(doc, currenty, 7);

          for (const config of component.Configs.filter(et => et.Virtual === false && et.ConstallationDependency === false)) {
            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal');
            doc.text(this.translate.GetTranslation(config.TranslationId) + ':', xDetail + 5, currenty);
            doc.setFont('helvetica', 'bold');

            const val = this.GetCurrentValue(config);

            let textWidth = 0;

            if (val as number) {
              doc.text((val as number).toString(), xDetail + 65, currenty);
              textWidth = doc.getTextWidth((val as number).toString());
            } if (val as string) {
              doc.text((val as string).toString(), xDetail + 65, currenty);
              textWidth = doc.getTextWidth((val as string).toString());
            }

            if (config.getUnitId()) {
              doc.text(this.translate.GetTranslation(config.getUnitId()), xDetail + 65 + textWidth + 2, currenty);
            }



            currenty = this.updateCurrentY(doc, currenty, 6);
          }

          currenty = this.updateCurrentY(doc, currenty, 2);

        }





        currenty = this.updateCurrentY(doc, currenty, 10);

      }
      doc.output('dataurlnewwindow');
      doc.save( 'BLUELINE Recipe ' + this.project.name + '.pdf');
    }

  }

  public updateCurrentY(doc: jsPDF.jsPDF, currenty: number, addOn: number): number {
    if (currenty + addOn + 20 > doc.internal.pageSize.getHeight()) {
      doc.addPage();

      doc.setFontSize(18);
      doc.text('Project: ', 11, 16);
      doc.text('Date: ', 11, 24);

      doc.setFont('helvetica', 'bold');
      doc.text(this.project.name, 40, 16);
      doc.text(this.datePipe.transform(new Date(), 'dd.MM.yyyy'), 40, 24);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text('Page ' + (doc.internal.pages.length - 1), doc.internal.pageSize.getWidth() -
        doc.getTextWidth('Site ' + doc.internal.pages.length) - 5, 24);

      doc.line(11, 32, 200, 32);

      currenty = 42;
      return currenty;
    } else {
      return currenty + addOn;
    }
  }

  public GetCurrentValue(moduledevicevalue: ComponentVariable): any {

    if (moduledevicevalue.States.length > 0) {
      if ((moduledevicevalue.CurrentValue !== null && moduledevicevalue.CurrentValue !== undefined) ||
        moduledevicevalue.CurrentValue === 0) {


        const fnd = moduledevicevalue.States.find(ex => ex.State === moduledevicevalue.CurrentValue);
        if (fnd) {
          const translated =  this.translate.GetTranslation(fnd.GetTranslationID());
          if (translated !== fnd.GetTranslationID()) {
            return translated;
          } else {
            return fnd.GetName();
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return moduledevicevalue.CurrentValue;
    }
  }

}
