import { Component, OnInit } from '@angular/core';
import {UpdateService} from '../../../services/update.service';

@Component({
  selector: 'app-update-progress',
  templateUrl: './update-progress.component.html',
  styleUrls: ['./update-progress.component.css']
})
export class UpdateProgressComponent implements OnInit {

  constructor(private update: UpdateService) { }

  ngOnInit(): void {
    this.update.performUpdate();
  }

}
