import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {QuestionBoxComponent} from '../question-box/question-box.component';
import {ConveyorTypes} from '../../../classes/conveyor-types';

@Component({
  selector: 'app-conveyor-selection-dialog',
  templateUrl: './conveyor-selection-dialog.component.html',
  styleUrls: ['./conveyor-selection-dialog.component.css']
})
export class ConveyorSelectionDialogComponent implements OnInit {

  public conveyorTypes: ConveyorTypes[]

  constructor(private dialogRef: MatDialogRef<ConveyorSelectionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              public dialog: MatDialog,
              public translate: BluectrlTranslateService) {

    this.conveyorTypes = data.conveyorTypes as ConveyorTypes[];
  }

  getPath(conveyorType: ConveyorTypes) {


    return '/assets/ConveyorTypes/'+conveyorType.Type+'.svg'
  }

  ngOnInit(): void {
  }

  CloseDialog() {
    this.dialogRef.close(null);
  }

  selected(conveyorType: ConveyorTypes) {
    this.dialogRef.close(conveyorType);
  }

}
