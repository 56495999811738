import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {SwUpdate, UpdateActivatedEvent} from '@angular/service-worker';
import {ModuleHandlingService} from './services/module-handling.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {VERSION} from '../environments/version';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import {QuestionBoxComponent} from './components/dialogs/question-box/question-box.component'; --not used
import {WebsocketService} from './services/v2/websocket.service';
import {WebsocketConnectionErrors} from './classes/enums/websocket-connection-errors';
import {environment} from '../environments/environment';
// import {UpdateProgressComponent} from './components/dialogs/update-progress/update-progress.component'; --not used
import {UpdateService} from './services/update.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import {VersionCheck} from './classes/VersionCheck';
import compareVersions from 'compare-versions';
import {ConnectivityService} from './services/connectivity.service';

@Component({
  selector: 'app-root',
  templateUrl: './main/app.component.html',
  styleUrls: ['./main/app.component.css']
})
export class AppComponent {
  title = 'app';
  public currentVersion: any;
  public previousVersion: any;
  public config: any;
  public updateHandler: any;

  deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;

  language = 'de';

  constructor(public router: Router,
              public updateService: UpdateService,
              public updates: SwUpdate,
              public moduleHandling: ModuleHandlingService,
              public dialog: MatDialog,
              private httpClient: HttpClient,
              public websocket: WebsocketService,
              public connectivity: ConnectivityService
              ) {
    // translate.addLangs(['en', 'de']);
    // translate.setDefaultLang('en');

    // const browserLang = translate.getBrowserLang();
    const userLang = navigator.language;
    // translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    this.config = VERSION;

  }


  private websocketConnected() {

  }

  private websocketConnectError(error: WebsocketConnectionErrors) {
  }



}
